// Création de la classe dialog
export default class Dialog {

	/**
	 * Constructor
	 */
	constructor() {
		this.constructor.id = "";
	}

	/**
	 * Initialise l'id du dialog
	 * 
	 * @param {string} id -> id du dialog
	 */
	setId(id) {
		this.id = id;
	}
	/**
	 * Récupère l'id du dialog
	 */
	getId() {
		return this.id;
	}
	/**
	 * Création d'une fenêtre de dialog
	 * 
	 * @param {string} titre -> titre du dialog
	 * @param {bool} boutons -> boutons ou pas
	 */
	create(titre, boutons) {

		//div principale
		let div_principale = document.createElement("div");
		div_principale.id = this.id;
		div_principale.className = "dialog";

		//div content
		let div_content = document.createElement("div");
		div_content.className = "dialog-content";

		//div titre
		let div_title = document.createElement("div");
		div_title.className = "dialog-title";
		div_title.innerHTML = "<b>" + titre + "</b>" + "<div class='dialog-close'></div>";
		div_content.appendChild(div_title);

		//div message
		let div_message = document.createElement("div");
		div_message.className = "dialog-message";
		div_content.appendChild(div_message);

		//s'il faut créer des boutons
		if (boutons == true) {

			//création des boutons
			let div_boutons = document.createElement("div");
			div_boutons.className = "dialog-boutons";

			//création du bouton enregistrer
			let valideBtn = document.createElement("button");
			valideBtn.type = "button";
			valideBtn.id = this.id + "_valider";
			valideBtn.innerHTML = "<span class='valideButton'></span> Valider";
			div_boutons.appendChild(valideBtn);

			//espace entre les boutons
			let espace = document.createTextNode('\u00A0\u00A0');
			div_boutons.appendChild(espace);

			//création du bouton annuler
			let cancelBtn = document.createElement("button");
			cancelBtn.type = "button";
			cancelBtn.id = this.id + "_annuler";
			cancelBtn.innerHTML = "<span class='cancelButton'></span> Annuler";
			cancelBtn.onclick = () => {
				this.hide();
			};
			div_boutons.appendChild(cancelBtn);

			//ajoute la div au dialog
			div_content.appendChild(div_boutons);
		}

		//ajoute le dialog à la page
		div_principale.appendChild(div_content);
		window.document.body.appendChild(div_principale);

		//action sur le bouton close
		document.querySelector("#" + this.id + " .dialog-close").onclick = () => {
			this.hide();
		};
	}
	/**
	 * Impose la width
	 * 
	 * @param {int} width -> width en pixels
	 */
	setWidth(width) {
		document.querySelector("#" + this.id + " .dialog-content").style.width = width + "px";
	}
	/**
	 * Mise à jour du titre
	 * 
	 * @param {string} titre -> titre du dialog
	 */
	updateTitle(titre) {
		document.querySelector("#" + this.id + " .dialog-title b").innerHTML = titre;
	}
	/**
	 * Mise à jour du contenu
	 * 
	 * @param {string} content -> contenu
	 */
	updateContent(content) {
		document.querySelector("#" + this.id + " .dialog-message").appendChild(content);
	}
	/**
	 * Affiche le dialog
	 */
	show() {
		document.getElementById(this.id).style.display = "flex";
	}
	/**
	 * Cache le dialog
	 */
	hide() {
		//ferme le dialog
		document.getElementById(this.id).style.display = "none";

		//reactive le bouton de confirmation
		if (document.getElementById(this.id + "_valider")) {
			objCommons.enableButton(this.id + "_valider", "valideButton");
		}
	}
	/**
	 * Cache la croix pour fermer
	 */
	hideClose() {
		document.querySelector("#" + this.id + " .dialog-close").style.display = "none";
	}
}