import tinymce from 'tinymce/tinymce';

// Création de la classe menu
export default class Menu {
	/**
	 * Constructor
	 */
	constructor() {

		//recupère le cookie remember_me_choix
		window.remember_me_choix = objCommons.getCookie("remember_me_choix");
		if (window.remember_me_choix == "") {
			window.remember_me_choix = "enseignant";
		}

		//cache les sous menus quand on clique a coté
		document.onmouseup = function (e) {
			if (e.target.id !== "menu") {
				document.querySelectorAll(".sous_menu").forEach(function (sous_menu) {
					sous_menu.style.display = "none";
				});
			}
		};

		//action sur le bouton vidéos dans la topbar
		if (document.getElementById("menu_videos")) {
			document.getElementById("menu_videos").addEventListener("click", function () {
				window.open(javascript_common_vars.url + "documents/manuel/enseignant#8.1");
			});
		}

		//action sur le bouton manuel dans la topbar
		if (document.getElementById("menu_manuel")) {
			document.getElementById("menu_manuel").addEventListener("click", function () {
				window.open(javascript_common_vars.url + "documents/voir");
			});
		}

		//action sur le bouton paramètres dans la topbar
		if (document.getElementById("menu_tools")) {
			document.getElementById("menu_tools").addEventListener("click", function () {
				location.href = javascript_common_vars.url + "utilisateur/parametres";
			});
		}

		//action sur le bouton connect dans la topbar
		if (document.getElementById("menu_connect")) {
			document.getElementById("menu_connect").addEventListener("click", function () {
				objMenu.logout();
			});
		}

		//si le bouton home existe
		if (document.getElementById("home")) {
			document.getElementById("home").addEventListener("click", function () {

				//si aucune session n'est ouverte
				if (javascript_common_vars.statut == "false") {

					//renvoie vers la page d'accueil
					location.href = javascript_common_vars.url;
				} else {

					//renvoie vers la page d'accueil du compte
					if (javascript_common_vars.statut == "eleve" || javascript_common_vars.statut == "etudiant") {
						location.href = javascript_common_vars.url + "eleve/accueil";
					} else {
						location.href = javascript_common_vars.url + "utilisateur/accueil";
					}
				}
			});
		}

		//action sur le lien licence dans le footer
		document.getElementById("lien_licence").addEventListener("click", function () {
			window.open(javascript_common_vars.url + "presentation/licence_utilisation");
		});

		//action sur le lien mentions dans le footer
		document.getElementById("lien_mentions").addEventListener("click", function () {
			window.open(javascript_common_vars.url + "presentation/mentions_legales");
		});

		//action sur le lien confidentialité dans le footer
		document.getElementById("lien_confidentialite").addEventListener("click", function () {
			window.open(javascript_common_vars.url + "presentation/confidentialite");
		});

		//action sur le lien contact dans le footer
		if (document.getElementById("lien_contact")) {
			document.getElementById("lien_contact").addEventListener("click", function () {
				objMenu.dialogContact();
			});
		}
	}
	/**
	 * Déconnexion d'un user
	 */
	logout() {

		//récupère l'url de logout
		$.ajax({
			type: "GET",
			url: javascript_common_vars.url + "logout/get_logout_url",
			cache: false
		}).done(function (url) {

			//si c'est un élève
			if (javascript_common_vars.statut == 'eleve') {
				objRatchet.sendCommand({
					command: "eleve_logout",
					id_eleve: javascript_page_vars.id_eleve
				});
			}

			//redirige
			location.href = javascript_common_vars.url + "logout/" + url;
		});
	}
	/**
	 * Création du dialog de contact
	 */
	dialogContact() {

		//récupère le contenu du dialog
		$.getJSON(javascript_common_vars.url + "contact/get_dialog_contact", function (data) {

			//id du dialog
			const id_dialog = "dialog_contact";
			objDialog.setId(id_dialog);

			//si le dialog n'existe pas
			if (!document.getElementById(id_dialog)) {

				//création du dialog
				objDialog.create("Formulaire de contact", true);

				//width
				objDialog.setWidth(600);

				//message d'alerte
				const div = document.createElement("div");
				if (data.dialog == "") {
					div.innerHTML = "Pour nous contacter, vous pouvez nous écrire directement à l'adresse suivante : <br><a href='mailto:contact-tacit@univ-rennes2.fr'>contact-tacit@univ-rennes2.fr</a>";
				} else {
					div.innerHTML = data.dialog;
				}
				div.style.textAlign = "center";
				div.style.minWidth = "400px";
				objDialog.updateContent(div);

				//si c'est pas GAR
				if (data.dialog != "") {

					// Initialiser TinyMCE
					tinymce.init({
						license_key: 'gpl',
						branding: false,
						language: "fr_FR",
						selector: "#message_contact",
						plugins: "autoresize,link",
						toolbar: "bold italic underline forecolor link",
						menubar: false,
						statusbar: false,
						newline_behavior: 'linebreak',
						browser_spellcheck: true,
						paste_as_text: true
					});

					//si c'est un signalement de bug
					document.getElementById("raison").addEventListener("change", function () {
						var div_aide = document.getElementById("aide_contact").querySelector(".texte_dans_cadre");
						switch (this.value) {
							case "bug":
								document.getElementById("select_bug").style.display = "table-row";
								break;
							case "inscription":
								div_aide.innerHTML = "Si vous avez perdu vos identifiants, vous pouvez les récupérer en cliquant sur le lien <b>« Pseudonyme oublié »</b> de la page de connexion.";
								document.getElementById("aide_contact").style.display = "table-row";
								document.getElementById("select_bug").style.display = "none";
								break;
							case "facturation":
								div_aide.innerHTML = "Vous avez la possibilité de générer des devis directement via le lien <b>« Demander un devis »</b> sur notre page d'accueil (<a target='_blank' href='" + javascript_common_vars.url + "'>" + javascript_common_vars.url + "</a>).";
								document.getElementById("aide_contact").style.display = "table-row";
								document.getElementById("select_bug").style.display = "none";
								break;
							case "acces":
								div_aide.innerHTML = "Si vous êtes enseignant, et que vous souhaitez mieux comprendre comment <b>lancer votre première séance</b>, vous pouvez visionner <a target='_blank' href='" + javascript_common_vars.url + "documents/manuel/enseignant#8.1'>ce tutoriel vidéo</a>.";
								document.getElementById("aide_contact").style.display = "table-row";
								document.getElementById("select_bug").style.display = "none";
								break;
							default:
								document.getElementById("aide_contact").style.display = "none";
								document.getElementById("select_bug").style.display = "none";
						}
					});

					//action sur le bouton de validation
					document.getElementById(id_dialog + "_valider").onclick = function () {
						objMenu.sendContact();
					};
				} else {
					//action sur le bouton de validation
					document.getElementById(id_dialog + "_valider").onclick = function () {
						objDialog.hide();
					};
				}
			}

			//ouvre le dialog
			objDialog.show();
		});
	}
	/**
	 * Envoie le formulaire de contact
	 */
	sendContact() {

		//si le formulaire est valide
		if (document.getElementById("nous_contacter").checkValidity()) {

			//si le description a été remplie
			if (tinymce.get("message_contact").getContent() != "") {

				//désactive le bouton d'envoi
				objCommons.disableButton(objDialog.getId() + "_valider");

				//récupère l'option selectionné
				var select = document.getElementById("bug_page");

				//envoi le formulaire de contact
				$.ajax({
					type: "POST",
					url: javascript_common_vars.url + "contact/send_contact",
					data: {
						"raison": document.getElementById("raison").value,
						"page": select.options[select.selectedIndex].text,
						"email": document.getElementById("email_contact").value,
						"message": tinymce.get("message_contact").getContent(),
						"navigateur": navigator.userAgent.toLowerCase()
					}
				}).done(function () {

					//cache le dialog
					objDialog.hide();

					//id du dialog
					const id_dialog = "dialog_contact_sent";
					objDialog.setId(id_dialog);

					//si le dialog n'existe pas
					if (!document.getElementById(id_dialog)) {

						//création du dialog
						objDialog.create("<div class='icone-valide'></div> Message envoyé", false);

						//message d'alerte
						const span = document.createElement("span");
						span.innerHTML = "Votre message a bien été envoyé à notre équipe,<br>nous vous recontacterons dans les plus brefs délais.<br><br>Cordialement,<br><b>L'équipe TACIT</b>";
						objDialog.updateContent(span);
					}

					//ouvre le dialog
					objDialog.show();
				});
			} else {
				objCommons.showFormError("form_errors_contact", "Vous n'avez pas écrit de message.");
			}
		} else {
			objCommons.highlightInvalid("nous_contacter");
		}
	}
	/**
	 * Actions sur le menu de la rubrique classe
	 */
	classes() {

		//renvoie sur la page des classes
		document.getElementById("menu_principal").addEventListener("click", function () {
			if (javascript_common_vars.statut == 'administrateur') {
				location.href = javascript_common_vars.url + "comptes/classes/" + javascript_page_vars.id_etablissement;
			} else {
				location.href = javascript_common_vars.url + "classes/liste";
			}
		});

		//ouvre le sous menu d'un groupe
		if (document.getElementById("menu_intermediaire")) {

			//si c'est le sous menu module
			if (document.getElementById("sous_menu_module")) {

				//menu module
				document.getElementById("menu_intermediaire").addEventListener("click", function () {
					var _coords = $(this).offset();
					document.getElementById("sous_menu_module").style.left = _coords.left + "px";
					document.getElementById("sous_menu_module").style.display = "block";
				});

				//boucle pour chaque module
				document.querySelectorAll("#sous_menu_module .sous_menu_item").forEach(function (module) {
					var _k = module.id.split("_")[3];

					//action sur le menu
					module.addEventListener("click", function () {
						if (typeof javascript_page_vars.resultats_eleve !== 'undefined') {
							location.href = javascript_common_vars.url + "resultats/eleve/" + javascript_page_vars.id_groupe + "/" + _k + "/" + document.getElementById("liste_eleves").value;
						} else {
							location.href = _k;
						}
					});
				});

				//couleur de l'interface en fonction du module
				document.body.className = "interface_" + javascript_page_vars.couleur_interface;
			} else {
				document.getElementById("menu_intermediaire").addEventListener("click", function () {
					location.href = "../eleves/" + javascript_page_vars.id_classe;
				});
			}
		}
	}
	/**
	 * Actions sur le menu de la rubrique comptes
	 */
	comptes() {

		//s'il y a le sous menu
		if (document.getElementById("sous_menu_comptes")) {

			//ouvre le sous menu facturation
			document.getElementById("menu_principal").addEventListener("click", function () {
				var _coords = $(this).offset();
				document.getElementById("sous_menu_comptes").style.left = _coords.left + "px";
				document.getElementById("sous_menu_comptes").style.display = "block";
			});

			//renvoie à la page de liste des comptes
			document.getElementById("sous_menu_liste").addEventListener("click", function () {
				location.href = javascript_common_vars.url + "comptes/liste/etablissements/1/all";
			});

			//renvoie à la page de liste des payeurs
			document.getElementById("sous_menu_resume").addEventListener("click", function () {
				location.href = javascript_common_vars.url + "comptes/resume";
			});
		} else {
			//renvoie à la page de liste des comptes
			document.getElementById("menu_principal").addEventListener("click", function () {
				location.href = javascript_common_vars.url + "comptes/liste/etablissements/1/all";
			});
		}
	}
	/**
	 * Actions sur le menu de la rubrique connexion
	 */
	connexion() {

		//action sur le bouton users
		document.getElementById("menu_principal").addEventListener("click", function () {
			location.href = javascript_common_vars.url + "connexion/utilisateur";
		});
	}
	/**
	 * Actions sur le menu de la rubrique documents
	 */
	documents() {

		//renvoie sur la page d'accueil des documents
		document.getElementById("menu_principal").addEventListener("click", function () {
			location.href = javascript_common_vars.url + "documents/voir";
		});
	}
	/**
	 * Actions sur le menu de la rubrique eleve
	 */
	eleve() {

		//couleur de l'interface en fonction du module
		document.body.className = "interface_" + javascript_page_vars.couleur_interface;
	}
	/**
	 * Actions sur le menu de la rubrique facturation
	 */
	facturation() {

		//ouvre le sous menu facturation
		document.getElementById("menu_principal").addEventListener("click", function () {
			var _coords = $(this).offset();
			document.getElementById("sous_menu_facturation").style.left = _coords.left + "px";
			document.getElementById("sous_menu_facturation").style.display = "block";
		});

		//renvoie à la page de liste des commandes
		document.getElementById("sous_menu_liste").addEventListener("click", function () {
			location.href = javascript_common_vars.url + "facturation/liste/1/all/all";
		});

		//renvoie à la page de liste des payeurs
		document.getElementById("sous_menu_payeurs").addEventListener("click", function () {
			location.href = javascript_common_vars.url + "facturation/payeurs/1/sans/all";
		});

		//renvoie à la page de resume
		document.getElementById("sous_menu_resume").addEventListener("click", function () {
			location.href = javascript_common_vars.url + "facturation/resume/all";
		});

		//renvoie à la page des bordereau
		document.getElementById("sous_menu_bordereau").addEventListener("click", function () {
			location.href = javascript_common_vars.url + "facturation/bordereaux/nouveaux/1";
		});

		//renvoie à la page de création de devis
		document.getElementById("sous_menu_devis").addEventListener("click", function () {
			location.href = javascript_common_vars.url + "facturation/devis/1/all";
		});
	}
	/**
	 * Actions sur le menu de la rubrique forum
	 */
	forum() {

		//action sur le menu principal
		document.getElementById("menu_principal").addEventListener("click", function () {
			location.href = javascript_common_vars.url + "forum/discussions";
		});

		//action sur le menu intermediaire
		if (document.getElementById("menu_intermediaire")) {
			document.getElementById("menu_intermediaire").addEventListener("click", function () {
				location.href = javascript_common_vars.url + "forum/liste_sujets/" + javascript_page_vars.id_discussion + "/1";
			});
		}
	}
	/**
	 * Actions sur le menu de la rubrique groupes
	 */
	groupes() {

		//renvoie à la page de liste des groupes
		document.getElementById("menu_principal").addEventListener("click", function () {
			location.href = javascript_common_vars.url + "groupes/liste";
		});

		//si c'est la page des precalibrations
		if (javascript_page_name == "calibrationsPrecalibration") {
			document.getElementById("menu_intermediaire").addEventListener("click", function () {
				location.href = javascript_common_vars.url + "calibrations/seance/" + javascript_page_vars.id_groupe;
			});
		}

		//ouvre le sous menu d'un groupe
		if (document.getElementById("sous_menu_module")) {

			//menu module
			document.getElementById("menu_intermediaire").addEventListener("click", function () {
				var _coords = $(this).offset();
				document.getElementById("sous_menu_module").style.left = _coords.left + "px";
				document.getElementById("sous_menu_module").style.display = "block";
			});

			//boucle pour chaque module
			document.querySelectorAll("#sous_menu_module .sous_menu_item").forEach(function (module) {
				var _k = module.id.split("_")[3];

				//action sur le menu
				module.addEventListener("click", function () {
					if (typeof javascript_page_vars.resultats_eleve !== 'undefined') {
						location.href = javascript_common_vars.url + "resultats/eleve/" + javascript_page_vars.id_groupe + "/" + _k + "/" + document.getElementById("liste_eleves").value;
					} else {
						location.href = _k;
					}
				});
			});

			//menu seance
			document.getElementById("menu_selected").addEventListener("click", function () {
				var _coords = $(this).offset();
				document.getElementById("sous_menu_seance").style.left = _coords.left + "px";
				document.getElementById("sous_menu_seance").style.display = "block";
			});

			//sous_menu evaluations
			document.getElementById("sous_menu_evals").addEventListener("click", function () {
				location.href = javascript_common_vars.url + "evaluations/seance/" + javascript_page_vars.id_groupe + "/" + javascript_page_vars.id_module;
			});

			//sous_menu entrainements
			document.getElementById("sous_menu_entrainements").addEventListener("click", function () {
				location.href = javascript_common_vars.url + "entrainements/seance/" + javascript_page_vars.id_groupe + "/" + javascript_page_vars.id_module;
			});

			//sous_menu classe inversee
			document.getElementById("sous_menu_classe_inversee").addEventListener("click", function () {
				location.href = javascript_common_vars.url + "entrainements/inversee/" + javascript_page_vars.id_groupe + "/" + javascript_page_vars.id_module;
			});

			//sous_menu resultats
			document.getElementById("sous_menu_resultats").addEventListener("click", function () {
				location.href = javascript_common_vars.url + "resultats/voir/" + javascript_page_vars.id_groupe + "/" + javascript_page_vars.id_module;
			});

			//couleur de l'interface en fonction du module
			document.body.className = "interface_" + javascript_page_vars.couleur_interface;
		}
	}
	/**
	 * Actions sur le menu de la rubrique items
	 */
	items() {

		//affiche le sous menu exercices
		document.getElementById("menu_principal").addEventListener("click", function () {
			var _coords = $(this).offset();
			document.getElementById("sous_menu_exercices").style.left = _coords.left + "px";
			document.getElementById("sous_menu_exercices").style.display = "block";
		});

		//action sur le sous menu resume
		if (document.getElementById("sous_menu_resume")) {
			document.getElementById("sous_menu_resume").addEventListener("click", function () {
				location.href = javascript_common_vars.url + "items/resume/" + javascript_page_vars.module.id;
			});
		}

		//action sur le sous menu liste
		document.getElementById("sous_menu_liste").addEventListener("click", function () {
			location.href = javascript_common_vars.url + "items/liste/" + javascript_page_vars.module.id + "/all/1";
		});

		//action sur le sous menu creation
		document.getElementById("sous_menu_creation").addEventListener("click", function () {
			location.href = javascript_common_vars.url + "items/creation/" + javascript_page_vars.module.id;
		});

		//action sur le sous menu revision
		document.getElementById("sous_menu_revision").addEventListener("click", function () {
			location.href = javascript_common_vars.url + "items/revision";
		});

		//couleur de l'interface en fonction du module
		document.body.className = "interface_" + javascript_page_vars.couleur_interface;
	}
	/**
	 * Actions sur le menu de la rubrique forum
	 */
	licence() {

		//action sur le menu principal
		document.getElementById("menu_principal").addEventListener("click", function () {
			if (javascript_common_vars.statut == 'facturation' || javascript_common_vars.statut == 'administrateur') {
				location.href = javascript_common_vars.url + "facturation/liste";
			} else {
				location.href = javascript_common_vars.url + "licence/gerer/last";
			}
		});
	}
	/**
	 * Actions sur le menu de la rubrique orthophoniste
	 */
	orthophoniste() {

		//action sur le menu principal
		document.getElementById("menu_principal").addEventListener("click", function () {
			location.href = javascript_common_vars.url + "orthophoniste/patients";
		});

		//action sur le menu intermediaire
		if (document.getElementById("menu_intermediaire")) {
			document.getElementById("menu_intermediaire").addEventListener("click", function () {
				location.href = javascript_common_vars.url + "patients/dossier/" + javascript_page_vars.id_patient;
			});
		}
	}
	/**
	 * Actions sur le menu de la rubrique présentation
	 * 
	 * @param {string} menu -> menu sélectionné
	 */
	presentation(menu) {

		//action sur le menu test
		document.getElementById("menu_test").addEventListener("click", function () {
			if (window.remember_me_choix == "enseignant") {
				location.href = javascript_common_vars.url + "inscription/test";
			} else {
				location.href = javascript_common_vars.url + "inscription/orthophoniste";
			}
		});

		//action sur le menu inscription
		if (document.getElementById("menu_inscription")) {
			document.getElementById("menu_inscription").addEventListener("click", function () {
				location.href = javascript_common_vars.url + "inscription/etablissement";
			});
		}

		//action sur le bouton connexion
		document.getElementById("menu_connexion").addEventListener("click", function () {
			location.href = javascript_common_vars.url + "connexion/utilisateur";
		});

		//selectionne le menu
		document.getElementById("menu_" + menu).classList.add("menu_selected");
	}
}