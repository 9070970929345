// Création de la classe ratchet
export default class Ratchet {
	/**
	 * Constructor
	 */
	constructor() {
		javascript_page_vars.conn = new WebSocket(javascript_common_vars.websocket);

		//quand c'est connecté
		javascript_page_vars.conn.onopen = function (e) {
		};

		//que faire quand l'user recois un message
		javascript_page_vars.conn.onmessage = function (e) {
			var data = JSON.parse(e.data);

			//quelle commande
			switch (data.command) {
				case "tchat_eleve":
					//si c'est un enseignant ou un formateur
					if (["enseignant", "enseignant_test", "formateur"].includes(javascript_common_vars.statut)) {

						//si le tchat est affiché et que l'élève qui a envoyé le message est celui sélectionné
						if (document.getElementById("tchat").style.display == "block" && javascript_page_vars.id_eleve == data.id_eleve) {

							//rafraichi la discussion
							objTchat.refreshTchatEleve(javascript_page_vars.id_eleve);
						} else {

							//refresh le tchat entier
							objTchat.refreshTchatEnseignant(false);
						}
					} else {
						//rafraichi la discussion
						objTchat.refreshTchatEleve(javascript_page_vars.id_eleve);
					}
					break;
				case "message":
					//si c'est un enseignant ou un formateur
					if (["enseignant", "enseignant_test", "formateur"].includes(javascript_common_vars.statut)) {

						//si le tchat est affiché et que l'élève qui a envoyé le message est celui sélectionné
						if (document.getElementById("tchat").style.display == "block" && data.ids_eleves.includes(parseInt(javascript_page_vars.id_eleve)) == true) {

							//rafraichi la discussion
							objTchat.refreshTchatEleve(javascript_page_vars.id_eleve);
						} else {
							//refresh le tchat entier
							objTchat.refreshTchatEnseignant(false);
						}
					} else {
						//rafraichi la discussion
						objTchat.refreshTchatEleve(javascript_page_vars.id_eleve);
					}
					break;
				case "delete_message":
					objTchat.confirmDelete(data.id_message);
					break;
				case "update_statut_calibration":
					//si l'élève est sur la page d'accueil
					if (javascript_page_vars.ids_groupes) {
						//rafraichi les séances
						objSeances.refresh();
					} else {
						//rafraichi le statut de la calibration
						objPassations.refreshStatutCalibration(data.statut);
					}
					break;
				case "update_statut_evaluation":
					//si l'élève est sur la page d'accueil
					if (javascript_page_vars.ids_groupes) {
						//rafraichi les séances
						objSeances.refresh();
					} else {
						//rafraichi le statut de l'évaluation
						objPassations.refreshStatutEvaluation(data.statut);
					}
					break;
				case "update_statut_entrainement":
					//si l'élève est sur la page d'accueil
					if (javascript_page_vars.ids_groupes) {
						//rafraichi les séances
						objSeances.refresh();
					} else {
						//rafraichi le statut de l'entrainement
						objPassations.refreshStatutEntrainement(data.statut);
					}
					break;
				case "passation_calibration":
					//si on est bien sur la page des calibrations
					if (javascript_page_name == "calibrationsSeance") {

						//rafraichi le tableau avec les calibrations
						objCalibration.refreshCalibrations();
					}
					break;
				case "passation_evaluation":
					//si on est bien sur la page des évaluations
					if (javascript_page_name == "evaluationsSeance") {

						//rafraichi le tableau avec les évaluations
						objEvaluation.refresh();
					}
					break;
				case "passation_entrainement":
					//si on est bien sur la page des entrainements
					if (javascript_page_name == "entrainementsSeance" || javascript_page_name == "entrainementsTutore") {

						//rafraichis les cases de couleurs
						objEntrainement.refreshTableauItems();

						//regarde si un item est affiché
						if (document.getElementById("show_item").style.display == "block") {

							//vérifie qu'il s'agit du bon entrainement
							if ((javascript_page_name == "entrainementsSeance" && javascript_page_vars.id_groupe_entrainement == data.id_eleve) || javascript_page_name == "entrainementsTutore") {

								//vérifie s'il s'agit de l'item passé
								if (javascript_page_vars.id_item == data.id_item && javascript_page_vars.timestamp == data.timestamp) {

									//délai de quelques microseconds pour refresh
									setTimeout(function () {

										//lance la fonction de mise à jour des reponses
										objEntrainement.refreshReponsesItem();
									}, 300);
								}
							}
						}
					}
					break;
				case "passation_patient":
					//si on est bien sur la page domicile et qu'il s'agit du même patient
					if (javascript_page_name == "patientsDomicile" && javascript_page_vars.id_patient == data.id_patient) {

						//rafraichis les cases de couleurs
						objPatients.refreshTableauItems();

						//regarde si l'item est affiché
						if (document.getElementById("show_item").style.display == "block" && javascript_page_vars.id_item == data.id_item) {

							//délai de quelques microseconds pour refresh
							setTimeout(function () {

								//lance la fonction de mise à jour des reponses
								objPatients.refreshReponsesItem();
							}, 300);
						}
					}
					break;
				case "eleve_login":
				case "eleve_logout":
					//si c'est un enseignant ou un formateur
					if (["enseignant", "enseignant_test", "formateur"].includes(javascript_common_vars.statut)) {

						//refresh le tchat entier
						objTchat.refreshTchatEnseignant(false);
					}
					break;
				case "eleve_force_logout":
					//si c'est l'élève connecté
					if ((javascript_common_vars.statut == "eleve" || javascript_common_vars.statut == "etudiant") && data.id_eleve == javascript_page_vars.id_eleve) {

						//déconnecte
						objMenu.logout();
					}
					break;
				case "eleve_delete_eval":
					//si c'est l'élève connecté
					if ((javascript_common_vars.statut == "eleve") && data.id_eleve == javascript_page_vars.id_eleve) {

						//retourne a la liste des séances
						location.href = javascript_common_vars.url + "eleve/accueil";
					}
					break;
				case "login_user":
					//si c'est l'user connecté
					if (data.id_user == javascript_common_vars.id_user) {

						//vérifie qu'il faut déconnecter l'user
						objCommons.checkAilleurs();
					}
					break;
			}
		};

		//ferme le socket
		window.addEventListener("beforeunload", function () {
			if (javascript_page_vars.conn.readyState == WebSocket.OPEN) {
				javascript_page_vars.conn.close();
				delete javascript_page_vars.conn;
			}
		});
	}
	/**
	 * Envoie une commande
	 * 
	 *  @param {obj} data -> contenu de la commande
	 * 
	 */
	sendCommand(data) {

		//si le websocket est pas prêt
		if (!javascript_page_vars.conn || javascript_page_vars.conn.readyState != WebSocket.OPEN) {

			//relance la commande
			setTimeout(function () {
				objRatchet.sendCommand(data);
			}, 500);
		} else {

			//envoie la commande
			javascript_page_vars.conn.send(JSON.stringify(data));
		}
	}
}