import $ from 'jquery';
import Commons from './commons';
import Dialog from './dialog';
import Menu from './menu';
import Ratchet from './ratchet';

export default class Loader {
	constructor() {

		//charge les fonctions communes
		window.$ = $;
		window.objCommons = new Commons();
		window.objDialog = new Dialog();
		window.objMenu = new Menu();
		window.objRatchet = new Ratchet();

		//vérifie le navigateur 
		objCommons.checkNavigator();

		//génère les tooltips pour tous les élèments ayant un "tooltip-content"
		document.querySelectorAll("[tooltip-content]").forEach(function (element) {
			objCommons.createTooltip(element);
		});

		//si c'est une page connectée et que c'est pas un eleve
		if (javascript_common_vars.statut != "false" && javascript_common_vars.statut != "eleve" && javascript_common_vars.statut != "etudiant" && javascript_common_vars.statut != "patient") {

			//subscribe au channel
			objRatchet.sendCommand({
				command: "subscribe",
				channel: "user",
				id_user: javascript_common_vars.id_user
			});

			//vérifie que la session existe toujours
			$(document).ajaxComplete(function (event, xhr, settings) {

				//si la session a expiré
				if (xhr.status === 401) {

					//on redirige vers la page d'accueil
					window.location.href = '/';
				}
			});
		}

		//cache le loader
		document.getElementById("preloader").style.display = "none";

		//lance la fonction de la page correspondante si elle existe
		if (typeof this[javascript_page_name] === 'function') {
			this[javascript_page_name]();
		}
	}
	/**
	 * Page de gestion des actualités
	 */
	actualitesGerer() {

		//importe les modules
		import('./actualite').then((Actualite) => {

			//créer un nouvel objet
			window.objActualite = new Actualite.default();

			//ouvre le dialog de création d'une actu
			document.getElementById("bouton_creer_actualite").addEventListener("click", function () {
				objActualite.openDialog(null);
			});

			//boucle pour chaque actualité de la page
			document.querySelectorAll("#tableau_actualites tbody tr").forEach(function (actualite) {
				if (actualite.hasAttribute('id')) {
					var _k = actualite.id.split("_")[1];

					//met a jour la visibilité d'une actu
					document.getElementById("checkbox_" + _k).addEventListener("change", function () {
						objActualite.visibilite(_k, this.checked);
					});

					//ouvre le dialog de modification d'une actu
					document.getElementById("modifier_" + _k).addEventListener("click", function () {
						objActualite.openDialog(_k);
					});

					//supprime une actu
					document.getElementById("supprimer_" + _k).addEventListener("click", function () {
						objActualite.dialogDelete(_k);
					});
				}
			});

			//rend le tableau des actualités triable par colonnes
			objCommons.sortTable(document.getElementById("tableau_actualites"));
		});
	}
	/**
	 * Page avec les données sur les connexions
	 */
	analyticsVoir() {

		//importe les modules
		import('./graphiques').then((Graphiques) => {

			//créer un nouvel objet
			window.objGraphiques = new Graphiques.default();

			//génère les graphiques en décalé
			objGraphiques.visiteurs();
		});
	}
	/**
	 * Page de précalibration
	 */
	calibrationsPrecalibration() {

		//initialise le menu
		objMenu.groupes();

		//importe les modules
		import('./calibration').then((Calibration) => {

			//créer un nouvel objet
			window.objCalibration = new Calibration.default();

			//cache la div de loading
			if (document.getElementById("div_contenu_item_loading")) {
				document.getElementById("div_contenu_item_loading").style.display = "none";
			}

			//valide l'exercice
			document.getElementById("bouton_valider_exercice").addEventListener("click", function () {
				objCommons.disableButton(this.id);
				objCalibration.preCalibration("valider");
			});

			//ne valide pas l'exercice
			document.getElementById("bouton_pas_valider_exercice").addEventListener("click", function () {
				document.getElementById("div_reponse").style.display = "none";
				document.getElementById("div_select").style.display = "block";
			});

			//choisi une raison
			document.getElementById("bouton_valider").addEventListener("click", function () {
				objCalibration.preCalibration("pas_valider");
			});

			//retourne au choix
			document.getElementById("bouton_annuler").addEventListener("click", function () {
				document.getElementById("div_reponse").style.display = "block";
				document.getElementById("div_select").style.display = "none";
			});
		});
	}
	/**
	 * Page des séances de calibration
	 */
	calibrationsSeance() {

		//initialise le menu
		objMenu.groupes();

		//importe les modules
		Promise.all([
			import('./calibration'),
			import('./tchat')
		]).then(([Calibration, Tchat]) => {

			//créer un nouvel objet
			window.objCalibration = new Calibration.default();
			window.objTchat = new Tchat.default();

			//si l'user est un administrateur
			if (javascript_common_vars.statut == "administrateur") {

				//modifie le format de calibration choisi
				document.getElementById("format_calibration").addEventListener("change", function () {
					objCalibration.update("format", this.value);
				});

				//modifie l'expé choisie
				document.getElementById("expe_calibration").addEventListener("change", function () {
					objCalibration.update("expe", this.value);
				});
			}

			//action sur le slider
			document.getElementById("slider_statut_calibration").addEventListener("change", function () {
				if (this.checked) {
					objCalibration.update("statut", "ouvert");
				} else {
					objCalibration.update("statut", "ferme");
				}
			});

			//rend les tableaux de détails sortable
			objCommons.sortTable(document.getElementById("table_calibration_detail"));

			//initialise le chat
			objTchat.init();

			//refresh le statut de la calibration
			objCalibration.refreshCalibrations();
		});
	}
	/**
	 * Page pour la création des élèves d'une classe
	 */
	classesCreateEleves() {

		//initialise le menu
		objMenu.classes();

		//importe les modules
		import('./eleves').then((Eleves) => {

			//créer un nouvel objet
			window.objEleves = new Eleves.default();

			//ajoute 5 lignes dans le tableau
			javascript_page_vars.num_ligne = 0;
			for (let i = 0; i < 5; i++) {
				objEleves.newLineEleve(true);
			}

			//ajoute une ligne nouvel eleve
			document.getElementById("bouton_ajouter_ligne").addEventListener("click", function () {
				objEleves.newLineEleve(true);
			});

			//ouvre le dialog d'importation des élèves
			document.getElementById("bouton_import_csv").addEventListener("click", function () {
				objEleves.dialogImport();
			});

			//valide la création d'élèves
			document.getElementById("valider").addEventListener("click", function () {
				objCommons.disableButton(this.id);
				objEleves.save();
			});

			//annule
			document.getElementById("annuler").addEventListener("click", function () {
				objCommons.disableButton(this.id);
				let currentURL = location.href;
				location.href = currentURL.replace('create_eleves', 'eleves');
			});

			//detecte changement de page
			window.addEventListener("beforeunload", function (e) {

				//si c'est pas une redirection après l'envoi du formulaire
				if (typeof javascript_page_vars.check_reload === "undefined") {

					//boucle pour chaque formulaire de la page
					var compteur = 0;
					document.querySelectorAll("#liste_nouveaux_eleves tbody tr").forEach(function (ligne) {
						var _k = ligne.id.split("_")[2];
						if (document.getElementById("nom_eleve_" + _k).value != "" || document.getElementById("prenom_eleve_" + _k).value != "") {
							compteur++;
						}
					});

					//si un élève est en cours de modif/création
					if (compteur != 0) {
						e.preventDefault();
						e.returnValue = "";
					} else {
						return "";
					}
				}
			});
		});
	}
	/**
	 * Page avec la liste des élèves d'une classe
	 */
	classesEleves() {

		//initialise le menu
		objMenu.classes();

		//importe les modules
		import('./eleves').then((Eleves) => {

			//créer un nouvel objet
			window.objEleves = new Eleves.default();

			//renvoie sur la page de création d'élèves
			document.getElementById("bouton_creer_eleve").addEventListener("click", function () {
				objCommons.disableButton(this.id);
				let currentURL = location.href;
				location.href = currentURL.replace('eleves', 'create_eleves');
			});

			//télécharge la liste des élèves en csv
			document.getElementById("bouton_export_csv").addEventListener("click", function () {
				window.open("../exporter");
			});

			//affiche le fichier parent
			document.getElementById("bouton_cartes_pdf").addEventListener("click", function () {
				window.open("../cartes_pdf/classe/" + javascript_page_vars.id_classe);
			});

			//si aucun élève
			if (document.getElementById("tableau_eleves").querySelectorAll("tbody .ellipsis").length == 0) {

				//affiche le dialog de consentement
				objEleves.consentement();
			} else {

				//boucle pour chaque eleve
				document.querySelectorAll("#tableau_eleves tbody tr").forEach(function (eleve) {
					var _k = eleve.id.split("_")[1];

					//réinitialise un pass
					if (document.getElementById("reset_pass_" + _k)) {
						document.getElementById("reset_pass_" + _k).addEventListener("click", function () {
							objEleves.resetPass(_k);
						});
					}

					//changement de classe
					if (document.getElementById("changer_classe_" + _k)) {
						document.getElementById("changer_classe_" + _k).addEventListener("click", function () {
							objEleves.dialogChangerClasse(_k);
						});
					}

					//modifie un élève
					if (document.getElementById("modifier_" + _k)) {
						document.getElementById("modifier_" + _k).addEventListener("click", function () {
							objEleves.dialogUpdate(_k);
						});
					}

					//supprime un élève
					if (document.getElementById("supprimer_" + _k)) {
						document.getElementById("supprimer_" + _k).addEventListener("click", function () {
							objEleves.dialogDelete(_k);
						});
					}

					//désactive un élève
					if (document.getElementById("desactiver_" + _k)) {
						document.getElementById("desactiver_" + _k).addEventListener("click", function () {
							objEleves.dialogDesactiver(_k);
						});
					}

					//réactive un élève
					if (document.getElementById("reactiver_" + _k)) {
						document.getElementById("reactiver_" + _k).addEventListener("click", function () {
							objEleves.reactiverEleve(_k);
						});
					}
				});

				//rend le tableau triable par colonne
				objCommons.sortTable(document.getElementById("tableau_eleves"));
			}
		});
	}
	/**
	 * Page avec la liste des classes
	 */
	classesListe() {

		//importe les modules
		import('./classes').then((Classes) => {

			//créer un nouvel objet
			window.objClasses = new Classes.default();

			//creation d'une classe
			document.getElementById("bouton_creer_classe").addEventListener("click", function () {
				objClasses.openDialog();
			});

			//boucle pour chaque classe de la page
			document.querySelectorAll("#tableau_classes tbody tr").forEach(function (classe) {
				if (classe.hasAttribute('id')) {
					var _k = classe.id.split("_")[1];

					//renvoie sur la page de gestion des élèves
					document.getElementById("eleves_" + _k).addEventListener("click", function () {
						location.href = "eleves/" + _k;
					});

					//renvoie sur la page d'affichage des résultats
					if (document.getElementById("resultats_" + _k)) {
						document.getElementById("resultats_" + _k).addEventListener("click", function () {
							location.href = "../resultats/voir/" + _k + "/1";
						});
					}

					//modifie une classe
					if (document.getElementById("modifier_" + _k)) {
						document.getElementById("modifier_" + _k).addEventListener("click", function () {
							objClasses.openDialog(_k);
						});
					}

					//supprime une classe
					if (document.getElementById("supprimer_" + _k)) {
						document.getElementById("supprimer_" + _k).addEventListener("click", function () {
							objClasses.dialogDelete(_k);
						});
					}
				}
			});

			//rend le tableau triable par colonne
			objCommons.sortTable(document.getElementById("tableau_classes"));
		});
	}
	/**
	 * Page avec la liste des abonnements GAR d'un établissement
	 */
	comptesAboGar() {

		//importe les modules
		import('./comptes').then((Comptes) => {

			//créer un nouvel objet
			window.objComptes = new Comptes.default();

			//initialise le menu
			objMenu.comptes();

			//suppression des abonnements
			document.getElementById("bouton_delete_abonnement").addEventListener("click", function () {
				objComptes.dialogDeleteAboGar();
			});
		});
	}
	/**
	 * Page avec la liste des classes d'un établissement
	 */
	comptesClasses() {

		//initialise le menu
		objMenu.comptes();
	}
	/**
	 * Page avec la liste des enseignants d'un établissement
	 */
	comptesEnseignants() {

		//initialise le menu
		objMenu.comptes();
	}
	/**
	 * Page avec la liste des comptes
	 */
	comptesListe() {

		//initialise le menu
		objMenu.comptes();

		//importe les modules
		Promise.all([
			import('./comptes'),
			import('./user')
		]).then(([Comptes, User]) => {

			//créer un nouvel objet
			window.objComptes = new Comptes.default();
			window.objUser = new User.default();

			//filtre les etablissements en fonction du type
			document.getElementById("filtre_etablissements").addEventListener("change", function () {
				const _search_value = document.getElementById("search").value || "all";
				location.href = "../../" + this.value + "/1/" + _search_value;
			});

			//renvoie a la page sélectionnée
			document.getElementById("num_page").addEventListener("change", function () {
				const _search_value = document.getElementById("search").value || "all";
				location.href = "../../" + document.getElementById("filtre_etablissements").value + "/" + this.value + "/" + _search_value;
			});

			//dialog de création d'un compte
			if (document.getElementById("bouton_creer_compte")) {
				const id_dialog = "dialog_creer_compte";

				//bouton de création
				document.getElementById("bouton_creer_compte").addEventListener("click", function () {
					objDialog.setId(id_dialog);
					objDialog.show();
				});

				//action sur le bouton de validation
				document.getElementById(id_dialog + "_valider").onclick = function () {
					objComptes.create();
				};

				//action sur le bouton d'annulation
				document.getElementById(id_dialog + "_annuler").onclick = function () {
					objDialog.hide();
				};
			}

			//lance une recherche
			document.getElementById("search").addEventListener("keypress", function (e) {

				//appuie sur "enter"
				if (e.keyCode == 13) {
					const _search_value = document.getElementById("search").value || "all";
					location.href = "../1/" + _search_value;
					return false;
				}
			});

			//lance une recherche
			if (document.getElementById("bouton_search")) {
				document.getElementById("bouton_search").addEventListener("click", function () {
					const _search_value = document.getElementById("search").value || "all";
					location.href = "../1/" + _search_value;
				});
			}

			//annule une recherche
			if (document.getElementById("bouton_cancel_search")) {
				document.getElementById("bouton_cancel_search").addEventListener("click", function () {
					location.href = "all";
				});
			}

			//boucle pour chaque enseignant
			document.getElementById("tableau_comptes").querySelectorAll("tbody tr").forEach(function (tr) {
				var _k = tr.id.split("_")[1];

				//ajoute une licence
				if (document.getElementById("ajout_licence_" + _k)) {
					document.getElementById("ajout_licence_" + _k).addEventListener("click", function () {
						objComptes.ajouterLicence(_k);
					});
				}

				//renvoie les identifiants d'un enseignant
				if (document.getElementById("identifiants_" + _k)) {
					document.getElementById("identifiants_" + _k).addEventListener("click", function () {
						objComptes.sendIdentifiants(_k);
					});
				}

				//supprimer le compte
				if (document.getElementById("supprimer_" + _k)) {
					document.getElementById("supprimer_" + _k).addEventListener("click", function () {
						objUser.dialogDelete(_k);
					});
				}
			});

			//rend le tableau triable par colonne
			objCommons.sortTable(document.getElementById("tableau_comptes"));
		});
	}
	/**
	 * Page de validation du compte
	 */
	comptesValideInscription() {

		//initialise le menu
		objMenu.comptes();
	}
	/**
	 * Page de résumé des comptes
	 */
	comptesResume() {

		//initialise le menu
		objMenu.comptes();

		//importe les modules
		Promise.all([
			import('./carte'),
			import('./graphiques')
		]).then(([Carte, Graphiques]) => {

			//créer un nouvel objet
			window.objCarte = new Carte.default();
			window.objGraphiques = new Graphiques.default();

			//affichage de l'onglet
			document.querySelectorAll(".fond_onglets div").forEach(function (element) {
				element.addEventListener("click", function () {
					objCommons.switchOnglet(element.id);
					objGraphiques.stackedChart("chart_" + element.id, "graphique_" + element.id);
				});
			});

			//modifie la carte en fonction de l'année selectionnée
			document.getElementById("annee_carte").addEventListener("change", function () {
				objCarte.create();
			});

			//modifie la carte en fonction du type de carte selectionné
			document.getElementById("type_carte").addEventListener("change", function () {
				objCarte.create();
			});

			//premier graphique
			objGraphiques.stackedChart("chart_effectif_comptes", "graphique_effectif_comptes");

			//création de la carte
			setTimeout(function () {
				objCarte.create();
			}, 1000);
		});
	}
	/**
	 * Page lorsqu'un compte est connecté ailleurs
	 */
	connexionAilleurs() {

		//renvoie vers la page de connexion
		document.getElementById("retour").addEventListener("click", function () {
			objCommons.disableButton(this.id);
			location.href = "utilisateur";
		});
	}
	/**
	 * Page de changement de mot de passe
	 */
	connexionChangerMdp() {

		//importe les modules
		import('./user').then((User) => {

			//créer un nouvel objet
			window.objUser = new User.default();

			//keypress sur les fields
			const fields = ["pass", "confirm_pass"];
			fields.forEach(field => {
				document.getElementById(field).addEventListener("keypress", function (e) {
					if (e.keyCode === 13) {
						objUser.resetPass();
						return false;
					}
				});
			});

			//estime la securite du mot de passe
			document.getElementById("pass").addEventListener("keyup", function () {
				objUser.evalPass();
			});

			//valide le changement de mot de passe
			document.getElementById("valider").addEventListener("click", function () {
				objUser.resetPass();
			});

			//renvoie sur la page de connexion
			document.getElementById("retour").addEventListener("click", function () {
				objCommons.disableButton(this.id);
				location.href = "../utilisateur";
			});
		});
	}
	/**
	 * Page de connexion des élèves
	 */
	connexionEleve() {

		//importe les modules
		import('./user').then((User) => {

			//créer un nouvel objet
			window.objUser = new User.default();

			//keypress sur les fields
			const fields = ["prenom", "nom", "pass"];
			fields.forEach(field => {
				document.getElementById(field).addEventListener("keypress", function (e) {
					if (e.keyCode === 13) {
						objUser.connexionEleve();
						return false;
					}
				});
			});

			//valide la connexion de l'élève
			document.getElementById("valider").addEventListener("click", function () {
				objUser.connexionEleve();
			});
		});
	}
	/**
	 * Trop d'étudiants
	 */
	connexionEtudiants() {

		//renvoie sur la page précédente
		document.getElementById("retour").addEventListener("click", function () {
			objCommons.disableButton(this.id);
			location.href = javascript_common_vars.url;
		});
	}
	/**
	 * Page de connexion des patients
	 */
	connexionPatient() {

		//importe les modules
		import('./user').then((User) => {

			//créer un nouvel objet
			window.objUser = new User.default();

			//keypress sur les fields
			const fields = ["prenom", "nom", "pass"];
			fields.forEach(field => {
				document.getElementById(field).addEventListener("keypress", function (e) {
					if (e.keyCode === 13) {
						objUser.connexionPatient();
						return false;
					}
				});
			});

			//valide la connexion du patient
			document.getElementById("valider").addEventListener("click", function () {
				objUser.connexionPatient();
			});
		});
	}
	/**
	 * Page de queue pour les élèves
	 */
	connexionQueue() {

		//importe les modules
		import('./user').then((User) => {

			//créer un nouvel objet
			window.objUser = new User.default();

			//lance la fonction de refresh des séances
			setTimeout(function () {
				objUser.refreshQueue();
			}, 300);
		});
	}
	/**
	 * Statut non autorisé
	 */
	connexionStatut() {

		//renvoie sur la page précédente
		document.getElementById("retour").addEventListener("click", function () {
			objCommons.disableButton(this.id);
			location.href = javascript_common_vars.url;
		});
	}
	/**
	 * Université non autorisée
	 */
	connexionUniversite() {

		//renvoie sur la page précédente
		document.getElementById("retour").addEventListener("click", function () {
			objCommons.disableButton(this.id);
			location.href = javascript_common_vars.url;
		});
	}
	/**
	 * Page de connexion des utilisateurs
	 */
	connexionUtilisateur() {

		//initialise le menu
		objMenu.presentation("connexion");

		//importe les modules
		import('./user').then((User) => {

			//créer un nouvel objet
			window.objUser = new User.default();

			//keypress sur les fields
			const fields = ["login", "pass"];
			fields.forEach(field => {
				document.getElementById(field).addEventListener("keypress", function (e) {
					if (e.keyCode === 13) {
						objUser.connexion();
						return false;
					}
				});
			});

			//affiche la page de récupération des identifiants
			document.getElementById("pseudo_oublie").addEventListener("click", function () {
				document.getElementById("div_connexion").style.display = "none";
				document.getElementById("div_recup").style.display = "block";
			});

			//affiche la page de récupération des identifiants
			document.getElementById("pass_oublie").addEventListener("click", function () {
				document.getElementById("div_connexion").style.display = "none";
				document.getElementById("div_recup").style.display = "block";
			});

			//valide la connexion de l'utilisateur
			document.getElementById("valider1").addEventListener("click", function () {
				objUser.connexion();
			});

			//keypress sur le champ email
			document.getElementById("email").addEventListener("keypress", function (e) {

				//appuie sur "enter"
				if (e.keyCode == 13) {
					objUser.recupererIdentifiants();
					return false;
				}
			});

			//action sur le code uai
			document.getElementById("code_uai").addEventListener("keyup", function (e) {

				//ignore le shift
				if (e.keyCode != 16) {
					objCommons.getEtablissement();
				}
			});

			//génère le select communes
			objCommons.getCommunes(true);

			//valide la récupération des identifiants
			document.getElementById("valider2").addEventListener("click", function () {
				objUser.recupererIdentifiants();
			});

			//annule la récupération des identifiants
			document.getElementById("retour2").addEventListener("click", function () {
				document.getElementById("div_connexion").style.display = "block";
				document.getElementById("div_recup").style.display = "none";
			});

			//annule la récupération des identifiants
			document.getElementById("retour3").addEventListener("click", function () {
				document.getElementById("div_recup2").style.display = "none";
				document.getElementById("div_connexion").style.display = "block";
			});
		});
	}
	/**
	 * Page interdite
	 */
	diversForbidden() {

		//renvoie sur la page précédente
		document.getElementById("retour").addEventListener("click", function () {
			objCommons.disableButton(this.id);
			location.href = "javascript:history.go(-1)";
		});
	}
	/**
	 * Page avec le diaporama
	 */
	documentsDiaporama() {

		//initialise le menu
		objMenu.documents();

		//importe les modules
		Promise.all([
			import('./carte'),
			import('./carousel')
		]).then(([Carte, Carousel]) => {

			//créer un nouvel objet
			window.objCarte = new Carte.default();
			window.objCarousel = new Carousel.default();

			//keyup sur la page
			document.addEventListener("keyup", function (e) {
				switch (e.keyCode) {
					case 37:
						objCarousel.plusSlides(-1);
						break;
					case 39:
						objCarousel.plusSlides(1);
						break;
				}
			});
		});
	}
	/**
	 * Page avec le manuel d'utilisation
	 */
	documentsManuel() {

		//initialise le menu
		objMenu.documents();

		//importe les modules
		import('./manuel').then((Manuel) => {

			//créer un nouvel objet
			window.objManuel = new Manuel.default();

			//charge le manuel
			objManuel.load();

			//renvoie en haut de la page
			document.getElementById("scroll_top").addEventListener("click", function () {
				document.getElementById("sommaire").scrollIntoView();
			});
		});
	}
	/**
	 * Page avec la liste des documents
	 */
	documentsVoir() {

		//recupère le cookie remember_me_choix
		let remember_me_choix = objCommons.getCookie("remember_me_choix") || "enseignant";

		//renvoie vers le manuel
		document.getElementById("lien_manuel").addEventListener("click", function () {
			location.href = "manuel/" + remember_me_choix;
		});

		//diaporama
		document.getElementById("lien_diaporama").addEventListener("click", function () {
			location.href = "diaporama/" + remember_me_choix;
		});
	}
	/**
	 * Page d'accueil d'un élève
	 */
	eleveAccueil() {

		//importe les modules
		Promise.all([
			import('./seances'),
			import('./tchat')
		]).then(([Seances, Tchat]) => {

			//créer un nouvel objet
			window.objSeances = new Seances.default();
			window.objTchat = new Tchat.default();

			//connexion eleve
			objRatchet.sendCommand({
				command: "eleve_login",
				id_eleve: javascript_page_vars.id_eleve
			});

			//rejoindre un groupe pour les étudiants
			if (document.getElementById("bouton_rejoindre_groupe")) {
				document.getElementById("bouton_rejoindre_groupe").addEventListener("click", function () {
					objSeances.rejoindreGroupe();
				});
			}

			//afficher les resultats
			if (document.getElementById("bouton_resultats")) {
				document.getElementById("bouton_resultats").addEventListener("click", function () {
					location.href = "resultats";
				});
			}

			//s'il faut demander la fusion des comptes GAR
			if (document.getElementById("dialog_fusion_gar")) {

				//importe les modules
				import('./gar').then((Gar) => {

					//créer un nouvel objet
					window.objGar = new Gar.default();
					objGar.dialogFusion();
				});
			}

			//si c'est un étudiant et qu'il faut demander des infos supp
			if (document.getElementById("dialog_infos_supplementaires")) {
				objSeances.dialogInfosSupplementaires();
			}

			//subscribe aux channels des groupes
			objRatchet.sendCommand({
				command: "subscribe",
				channel: "groupes",
				ids_groupes: javascript_page_vars.ids_groupes
			});

			//refresh les séances
			objSeances.refresh();

			//initialise le chat
			objTchat.init();
		});
	}
	/**
	 * Page d'évaluation en autonomie d'un étudiant
	 */
	eleveAutonomie() {

		//initialise le menu
		objMenu.eleve();

		//importe les modules
		import('./passations').then((Passations) => {

			//créer un nouvel objet
			window.objPassations = new Passations.default();

			//vérifie la réponse de l'élève
			if (document.getElementById("bouton_valider_reponse")) {
				document.getElementById("bouton_valider_reponse").addEventListener("click", function () {
					objPassations.repondreAutonomie();
				});
			}

			//scroll jusqu'au menu
			document.getElementById("menu").scrollIntoView();

			//empeche le copié collé
			objCommons.disableSelection();
		});
	}
	/**
	 * Page de calibration d'un élève
	 */
	eleveCalibration() {

		//initialise le menu
		objMenu.eleve();

		//importe les modules
		Promise.all([
			import('./passations'),
			import('./tchat')
		]).then(([Passations, Tchat]) => {

			//créer un nouvel objet
			window.objPassations = new Passations.default();
			window.objTchat = new Tchat.default();

			//vérifie la réponse de l'élève
			if (document.getElementById("bouton_valider_reponse")) {
				document.getElementById("bouton_valider_reponse").addEventListener("click", function () {
					objPassations.repondreCalibration();
				});
			}

			//passe à la question suivante
			if (document.getElementById("bouton_question_suivante")) {
				document.getElementById("bouton_question_suivante").addEventListener("click", function () {
					objCommons.disableButton(this.id);
					location.reload();
				});
			}

			//ajoute un commentaire sur l'exercice
			if (document.getElementById("bouton_enregistrer_commentaire")) {

				//importe les modules
				import('./item').then((Item) => {

					//créer un nouvel objet
					window.objItem = new Item.default();

					document.getElementById("bouton_enregistrer_commentaire").addEventListener("click", function () {
						objItem.newCommentaire(javascript_page_vars.id_item, document.getElementById("nouveau_commentaire").value, true);
					});

					//ajoute un commentaire sur l'exercice
					document.getElementById("nouveau_commentaire").addEventListener("keypress", function (e) {

						//appuie sur "enter"
						if (e.keyCode == 13) {
							objItem.newCommentaire(javascript_page_vars.id_item, document.getElementById("nouveau_commentaire").value, true);
							return false;
						}
					});
				});
			}

			//affiche les résultats de la calibration au clic sur le petit 2
			if (document.getElementById("div_contenu_item")) {
				document.getElementById("lien_resultats_calibration").addEventListener("click", function () {
					objPassations.resultatsCalibration();
				});
			}

			//scroll jusqu'au menu
			document.getElementById("menu").scrollIntoView();

			//cache la div de loading
			if (document.getElementById("div_contenu_item_loading")) {
				document.getElementById("div_contenu_item_loading").style.display = "none";
			}

			//subscribe au channel du groupe_calibration
			objRatchet.sendCommand({
				command: "subscribe",
				channel: "groupe_calibration",
				id_groupe_calibration: javascript_page_vars.id_groupe_calibration
			});

			//empeche le copié collé
			objCommons.disableSelection();

			//initialise le chat
			objTchat.init();
		});
	}
	/**
	 * Page d'entrainement d'un élève
	 */
	eleveEntrainement() {
		javascript_page_vars.lecture_audio = false;

		//problème de firefox
		if (document.getElementById("form_question_preparatoire") != null) {
			document.getElementById("form_question_preparatoire").reset();
		}
		if (document.getElementById("form_question_principale") != null) {
			document.getElementById("form_question_principale").reset();
		}

		//initialise le menu
		objMenu.eleve();

		//importe les modules
		Promise.all([
			import('./passations'),
			import('./lirecouleur'),
			import('./tchat')
		]).then(([Passations, Lirecouleur, Tchat]) => {

			//créer un nouvel objet
			window.objPassations = new Passations.default();
			window.objLireCouleur = new Lirecouleur.default();
			window.objTchat = new Tchat.default();

			//si le menu de choix de la police existe
			if (document.getElementById("font_size")) {

				//initialise les parametres avec les valeurs enregistrées
				objLireCouleur.initParametresAffichage();

				//lance la fonction lireCouleur
				objLireCouleur.dysTexte(javascript_page_vars.affichage.lire_couleur, false);
			} else {
				objLireCouleur.dysTexte("normal", false);
			}

			//renvoie vers la page d'accueil
			if (document.getElementById("accueil")) {
				document.getElementById("accueil").addEventListener("click", function () {
					objCommons.disableButton(this.id);
					location.href = "../../eleve/accueil";
				});
			}

			//vérifie la réponse de l'élève
			if (document.getElementById("bouton_valider_reponse")) {
				document.getElementById("bouton_valider_reponse").addEventListener("click", function () {
					objPassations.repondreEntrainement();
				});
			}

			//appuie sur entrée une fois le champ rempli
			if (document.getElementById("champ_ouvert_principale")) {
				document.getElementById("champ_ouvert_principale").addEventListener("keypress", function (e) {

					//appuie sur "enter"
					if (e.keyCode == 13) {
						objPassations.repondreEntrainement();
					}
				});
			}

			//passe à la question suivante
			if (document.getElementById("bouton_question_suivante")) {
				document.getElementById("bouton_question_suivante").addEventListener("click", function () {
					objCommons.disableButton(this.id);
					location.reload();
				});
			}

			//scroll jusqu'au menu
			document.getElementById("menu").scrollIntoView();

			//cache la div de loading
			if (document.getElementById("div_contenu_item_loading")) {
				document.getElementById("div_contenu_item_loading").style.display = "none";
			}

			//subscribe au channel du groupe_entrainement
			objRatchet.sendCommand({
				command: "subscribe",
				channel: "groupe_entrainement",
				id_groupe_entrainement: javascript_page_vars.id_groupe_entrainement,
				type_seance: javascript_page_vars.type_seance
			});

			//empeche le copié collé
			objCommons.disableSelection();

			//initialise le chat
			objTchat.init();
		});
	}
	/**
	 * Page d'évaluation d'un élève
	 */
	eleveEvaluation() {
		javascript_page_vars.lecture_audio = false;

		//problème de firefox
		if (document.getElementById("form_question_principale") != null) {
			document.getElementById("form_question_principale").reset();
		}

		//initialise le menu
		objMenu.eleve();

		//importe les modules
		Promise.all([
			import('./passations'),
			import('./tchat')
		]).then(([Passations, Tchat]) => {

			//créer un nouvel objet
			window.objPassations = new Passations.default();
			window.objTchat = new Tchat.default();

			//renvoie vers la page d'accueil
			if (document.getElementById("accueil")) {
				document.getElementById("accueil").addEventListener("click", function () {
					objCommons.disableButton(this.id);
					location.href = "../../eleve/accueil";
				});
			}

			//vérifie la réponse de l'élève
			if (document.getElementById("bouton_valider_reponse")) {
				document.getElementById("bouton_valider_reponse").addEventListener("click", function () {
					objPassations.repondreEvaluation();
				});
			}

			//scroll jusqu'au menu
			document.getElementById("menu").scrollIntoView();

			//cache la div de loading
			if (document.getElementById("div_contenu_item_loading")) {
				document.getElementById("div_contenu_item_loading").style.display = "none";
			}

			//subscribe au channel du groupe_evaluation 
			objRatchet.sendCommand({
				command: "subscribe",
				channel: "groupe_evaluation",
				id_groupe_evaluation: javascript_page_vars.id_groupe_evaluation
			});

			//empeche le copié collé
			objCommons.disableSelection();

			//initialise le chat
			objTchat.init();
		});
	}
	/**
	 * Page de résultats d'un élève
	 */
	eleveResultats() {

		//renvoie vers la page d'accueil
		document.getElementById("accueil").addEventListener("click", function () {
			objCommons.disableButton(this.id);
			location.href = "accueil";
		});
	}
	/**
	 * Page avec la liste des enseignant d'un établissement
	 */
	enseignantsListe() {

		//importe les modules
		Promise.all([
			import('./comptes'),
			import('./enseignants'),
			import('./user')
		]).then(([Comptes, Enseignants, User]) => {

			//créer un nouvel objet
			window.objComptes = new Comptes.default();
			window.objEnseignants = new Enseignants.default();
			window.objUser = new User.default();

			//boucle pour chaque enseignant
			document.getElementById("tableau_enseignants").querySelectorAll("tbody tr").forEach(function (tr) {
				var _k = tr.id.split("_")[1];

				//renvoie les identifiants d'un enseignant
				if (document.getElementById("identifiants_" + _k)) {
					document.getElementById("identifiants_" + _k).addEventListener("click", function () {
						objComptes.sendIdentifiants(_k);
					});
				}

				//supprime l'enseignant
				if (document.getElementById("supprimer_" + _k)) {
					document.getElementById("supprimer_" + _k).addEventListener("click", function () {
						objUser.dialogDelete(_k);
					});
				}
			});

			//ajoute un nouvel enseignant
			document.getElementById("bouton_creer_enseignant").addEventListener("click", function () {
				objEnseignants.openDialog();
			});

			//rend le tableau triable par colonne
			objCommons.sortTable(document.getElementById("tableau_enseignants"));
		});
	}
	/**
	 * Page des séances d'entrainement
	 */
	entrainementsSeance() {
		javascript_page_vars.id_last_passation = null;

		//initialise le menu
		objMenu.groupes();

		//importe les modules
		Promise.all([
			import('./eleveParametres'),
			import('./entrainement'),
			import('./lirecouleur'),
			import('./tchat')
		]).then(([EleveParametres, Entrainement, Lirecouleur, Tchat]) => {

			//créer un nouvel objet
			window.objEleveParametres = new EleveParametres.default();
			window.objEntrainement = new Entrainement.default();
			window.objLireCouleur = new Lirecouleur.default();
			window.objTchat = new Tchat.default();

			//filtre des entrainements
			if (document.getElementById("filtre_entrainements")) {
				document.getElementById("filtre_entrainements").addEventListener("keyup", function () {
					objEntrainement.filtreEleves();
				});
			}

			//action sur le tri des résultats
			if (document.getElementById("tri_resultats")) {
				document.getElementById("tri_resultats").addEventListener("change", function () {
					objEntrainement.updateTri();
				});
			}

			//répartition automatique
			if (document.getElementById("repartition")) {
				document.getElementById("repartition").addEventListener("click", function () {
					objEntrainement.dialogRepartition();
				});
			}

			//ouvrir tous les entrainements
			if (document.getElementById("ouvrir_tout")) {
				document.getElementById("ouvrir_tout").addEventListener("click", function () {
					objEntrainement.statut("all", "ouvert");
				});
			}

			//fermer tous les entrainements
			if (document.getElementById("fermer_tout")) {
				document.getElementById("fermer_tout").addEventListener("click", function () {
					objEntrainement.statut("all", "ferme");
				});
			}

			//accès au mode tutore
			if (document.getElementById("mode_tutore")) {
				document.getElementById("mode_tutore").addEventListener("click", function () {
					objCommons.disableButton(this.id);
					location.href = "../../tutore/" + javascript_page_vars.id_groupe + "/" + javascript_page_vars.id_module;
				});
			}

			//s'il y a les dates (classe inversée)
			if (document.getElementById("date_debut")) {

				//date début
				document.getElementById("date_debut").addEventListener("change", function () {
					objEntrainement.dates();
				});

				//heure début
				document.getElementById("heure_debut").addEventListener("change", function () {
					objEntrainement.dates();
				});

				//date fin
				document.getElementById("date_fin").addEventListener("change", function () {
					objEntrainement.dates();
				});

				//heure fin
				document.getElementById("heure_fin").addEventListener("change", function () {
					objEntrainement.dates();
				});
			}

			//initialise les parametres
			objEleveParametres.init();
			objEntrainement.initParametres();

			//boucle pour chaque entrainement
			document.querySelectorAll("#liste_groupes_entrainements .div_bottom_container").forEach(function (entrainement) {
				var _k = entrainement.id.split("_")[2];

				//initialise le select2
				objEntrainement.initSelectNiveau(entrainement.id);

				//paramètres
				document.getElementById(entrainement.id + "_parametres").addEventListener("click", function () {
					objEntrainement.parametres(_k);
				});

				//nouvelle série
				document.getElementById(entrainement.id + "_nouvelle_serie").addEventListener("click", function () {
					if (this.disabled != true) {
						objEntrainement.openDialogNouvelleSerie(_k);
					}
				});

				//action sur le slider
				if (document.getElementById("slider_statut_" + entrainement.id)) {
					document.getElementById("slider_statut_" + entrainement.id).addEventListener("change", function () {
						if (this.checked) {
							objEntrainement.statut(_k, "ouvert");
						} else {
							objEntrainement.statut(_k, "ferme");
						}
					});
				}
			});

			//case nouvelle série
			document.getElementById("nouvelle_serie_all").addEventListener("change", function () {
				var checked = this.checked;
				document.querySelectorAll('[name="cases_nouvelle_serie"]:not([disabled])').forEach(function (element) {
					element.checked = checked;
				});
			});

			//valide le lancement d'une nouvelle série
			document.getElementById("dialog_nouvelle_serie_valider").addEventListener("click", function () {
				objEntrainement.valideNouvelleSerie();
			});

			//annule le lancement d'une nouvelle série
			document.getElementById("dialog_nouvelle_serie_annuler").addEventListener("click", function () {
				objDialog.hide();
			});

			//action sur le bouton commentaire
			document.getElementById("commenter_item").addEventListener("click", function () {

				//importe les modules
				import('./item').then((Item) => {

					//créer un nouvel objet
					window.objItem = new Item.default();
					objItem.dialogCommentaire();
				});
			});

			//action sur le bouton fermer item
			document.getElementById("fermer_show_item").addEventListener("click", function () {
				document.getElementById("show_item").style.display = "none";
				document.querySelectorAll(".case_item").forEach(function (case_item) {
					case_item.classList.remove("case_item_selected");
				});
			});

			//modifie le mode de vue
			document.getElementById("vue_neutre").addEventListener("change", function () {
				if (this.checked == true) {
					objEntrainement.updateVueItem("neutre");
				}
			});

			//modifie le mode de vue
			document.getElementById("vue_eleve").addEventListener("change", function () {
				if (this.checked == true) {
					objEntrainement.updateVueItem("eleve");
				}
			});

			//rafraichis les cases de couleurs
			objEntrainement.refreshTableauItems();

			//desactive la selection sur les items
			objCommons.disableSelection();

			//initialise le chat
			objTchat.init();
		});
	}
	/**
	 * Page d'un entrainement tutoré
	 */
	entrainementsTutore() {
		javascript_page_vars.id_last_passation = null;

		//initialise le menu
		objMenu.groupes();

		//importe les modules
		Promise.all([
			import('./entrainement'),
			import('./lirecouleur'),
			import('./tchat')
		]).then(([Entrainement, Lirecouleur, Tchat]) => {

			//créer un nouvel objet
			window.objEntrainement = new Entrainement.default();
			window.objLireCouleur = new Lirecouleur.default();
			window.objTchat = new Tchat.default();

			//accès au mode autonomie
			document.getElementById("mode_autonomie").addEventListener("click", function () {
				objCommons.disableButton(this.id);
				location.href = "../../seance/" + javascript_page_vars.id_groupe + "/" + javascript_page_vars.id_module;
			});

			//initialise le select2
			objEntrainement.initSelectNiveau("groupe_entrainement_" + javascript_page_vars.id_groupe_entrainement_tutore);

			//paramètres
			document.getElementById("groupe_entrainement_" + javascript_page_vars.id_groupe_entrainement_tutore + "_parametres").addEventListener("click", function () {
				objEntrainement.parametres(javascript_page_vars.id_groupe_entrainement_tutore);
			});

			//action sur le slider
			document.getElementById("slider_statut_groupe_entrainement_" + javascript_page_vars.id_groupe_entrainement_tutore).addEventListener("change", function () {
				if (this.checked) {
					objEntrainement.statut(javascript_page_vars.id_groupe_entrainement_tutore, "ouvert");
				} else {
					objEntrainement.statut(javascript_page_vars.id_groupe_entrainement_tutore, "ferme");
				}
			});

			//initialise les parametres
			objEntrainement.initParametres();

			//menu clic droit sur les cases item
			document.querySelectorAll(".items_niveau .case_item").forEach(function (case_item) {
				case_item.addEventListener("contextmenu", function (e) {
					objEntrainement.marquerItemTutore(case_item, e);
				});
			});

			//afficher l'exercice aux élèves
			objCommons.toggleButton("select_item");
			document.getElementById("select_item").addEventListener("click", function () {
				objEntrainement.selectItemTutore();
			});

			//afficher/cacher les bonnes réponses
			javascript_page_vars.bonnes_reponses_toggled = false;
			document.getElementById("show_bonnes_reponses").addEventListener("click", function () {
				if (javascript_page_vars.bonnes_reponses_toggled) {
					objEntrainement.hideBonnesRep();
					javascript_page_vars.bonnes_reponses_toggled = false;
				} else {
					objEntrainement.showBonnesRep();
					javascript_page_vars.bonnes_reponses_toggled = true;
				}
				objEntrainement.updateParametres("bonnes_reponses", javascript_page_vars.bonnes_reponses_toggled);
			});

			//afficher/cacher les réponses élèves
			javascript_page_vars.reponses_eleves_toggled = false;
			document.getElementById("show_reponses_eleves").addEventListener("click", function () {
				if (javascript_page_vars.reponses_eleves_toggled) {
					objEntrainement.hideRepEleves();
					javascript_page_vars.reponses_eleves_toggled = false;
				} else {
					objEntrainement.showRepEleves();
					javascript_page_vars.reponses_eleves_toggled = true;
				}
			});

			//reset un exercice
			document.getElementById("rejouer_item").addEventListener("click", function () {
				objEntrainement.resetItemTutore();
			});

			//action sur le bouton fermer item
			document.getElementById("commenter_item").addEventListener("click", function () {

				//importe les modules
				import('./item').then((Item) => {

					//créer un nouvel objet
					window.objItem = new Item.default();
					objItem.dialogCommentaire();
				});
			});

			//action sur le bouton fermer item
			document.getElementById("fermer_show_item").addEventListener("click", function () {
				document.getElementById("show_item").style.display = "none";
				document.querySelectorAll(".case_item").forEach(function (case_item) {
					case_item.classList.remove("case_item_selected");
				});
			});

			//modifie le mode de vue
			document.getElementById("vue_neutre").addEventListener("change", function () {
				if (this.checked == true) {
					objEntrainement.updateVueItem("neutre");
				}
			});

			//modifie le mode de vue
			document.getElementById("vue_tbi").addEventListener("change", function () {
				if (this.checked == true) {
					objEntrainement.updateVueItem("tbi");
				}
			});

			//rafraichis les cases de couleurs
			objEntrainement.refreshTableauItems();

			//desactive la selection sur les items
			objCommons.disableSelection();

			//initialise le chat
			objTchat.init();
		});
	}
	/**
	 * Page des séances d'évaluation
	 */
	evaluationsSeance() {

		//initialise le menu
		objMenu.groupes();

		//importe les modules
		Promise.all([
			import('./eleveParametres'),
			import('./evaluation'),
			import('./tchat')
		]).then(([EleveParametres, Evaluation, Tchat]) => {

			//créer un nouvel objet
			window.objEleveParametres = new EleveParametres.default();
			window.objEvaluation = new Evaluation.default();
			window.objTchat = new Tchat.default();

			//action sur le bouton creer
			document.getElementById("bouton_creer").addEventListener("click", function () {
				objEvaluation.create("false");
			});

			//action sur la liste des évals
			if (document.getElementById("selected_evaluation")) {
				document.getElementById("selected_evaluation").addEventListener("change", function () {

					//cache les évals
					document.querySelectorAll("#liste_groupes_evaluations .span_nb_eleves, #liste_groupes_evaluations .div_titre_boutons, #liste_groupes_evaluations .div_resultats_evaluations").forEach(function (div) {
						div.style.display = "none";
					});

					//affiche l'évaluation sélectionnée
					document.getElementById("evaluation_" + this.value + "_nb_eleves").style.display = "inline-block";
					document.getElementById("evaluation_" + this.value + "_boutons").style.display = "block";
					document.getElementById("evaluation_" + this.value + "_resultats").style.display = "block";
				});
			}

			//boucle pour chaque evaluation
			document.querySelectorAll("#liste_groupes_evaluations .div_titre_boutons").forEach(function (tr) {
				var _k = tr.id.split("_")[1];

				//action sur le slider
				document.getElementById("slider_statut_evaluation_" + _k).addEventListener("change", function () {
					if (this.checked) {
						objEvaluation.statut(_k, "ouvert");
					} else {
						objEvaluation.statut(_k, "ferme");
					}
				});
			});

			//rend les tableaux de détails sortable
			document.querySelectorAll(".table_evaluation_detail").forEach(function (table) {
				objCommons.sortTable(table);
			});

			//initialise les parametres
			objEleveParametres.init();

			//refresh des donnees des evaluations
			objEvaluation.refresh();

			//initialise le chat
			objTchat.init();
		});
	}
	/**
	 * Page avec la liste des bordereaux
	 */
	facturationBordereaux() {

		//initialise le menu
		objMenu.facturation();

		//importe les modules
		import('./bordereau').then((Bordereau) => {

			//créer un nouvel objet
			window.objBordereau = new Bordereau.default();

			//renvoie a la page sélectionnée
			document.getElementById("num_page").addEventListener("change", function () {
				location.href = this.value;
			});

			//lance la création d'un nouveau bordereau publics
			document.getElementById("bouton_creer_bordereau_public").addEventListener("click", function () {
				objCommons.disableButton(this.id);
				objBordereau.create("Public");
			});

			//lance la création d'un nouveau bordereau privés
			document.getElementById("bouton_creer_bordereau_prive").addEventListener("click", function () {
				objCommons.disableButton(this.id);
				objBordereau.create("Privé");
			});
		});
	}
	/**
	 * Page de création des devis
	 */
	facturationDevis() {

		//initialise le menu
		objMenu.facturation();

		//importe les modules
		import('./facturation').then((Facturation) => {

			//créer un nouvel objet
			window.objFacturation = new Facturation.default();

			//renvoie a la page sélectionnée
			document.getElementById("num_page").addEventListener("change", function () {
				location.href = this.value;
			});

			//lance une recherche sur les devis
			document.getElementById("rechercher_devis").addEventListener("keypress", function (e) {

				//appuie sur "enter"
				if (e.keyCode == 13) {
					const _search_value = this.value || "all";
					location.href = _search_value;
					return false;
				}
			});

			//lance une recherche sur les devis
			if (document.getElementById("bouton_rechercher_devis")) {
				document.getElementById("bouton_rechercher_devis").addEventListener("click", function () {
					const _search_value = document.getElementById("rechercher_devis").value || "all";
					location.href = _search_value;
				});
			}

			//annule une recherche
			if (document.getElementById("bouton_cancel_rechercher_devis")) {
				document.getElementById("bouton_cancel_rechercher_devis").addEventListener("click", function () {
					location.href = "all";
				});
			}
		});
	}
	/**
	 * Page avec la liste des commandes
	 */
	facturationListe() {

		//initialise le menu
		objMenu.facturation();

		//importe les modules
		Promise.all([
			import('./commandes'),
			import('./facturation'),
			import('./messages')
		]).then(([Commandes, Facturation, Messages]) => {

			//créer un nouvel objet
			window.objCommandes = new Commandes.default();
			window.objFacturation = new Facturation.default();
			window.objMessages = new Messages.default();

			//filtre les commandes en fonction du statut
			document.getElementById("statut_commande").addEventListener("change", function () {
				const _search_value = document.getElementById("rechercher_commandes").value || "all";
				location.href = "../../1/" + this.value + "/" + _search_value;
			});

			//renvoie a la page sélectionnée
			document.getElementById("num_page").addEventListener("change", function () {
				const _search_value = document.getElementById("rechercher_commandes").value || "all";
				location.href = "../../" + this.value + "/" + document.getElementById("statut_commande").value + "/" + _search_value;
			});

			//importe le fichier
			document.getElementById("file_xlsx").addEventListener("change", function () {
				document.getElementById("input_file_import_txt").innerHTML = "<img src='/img/loading1.gif' style='margin:2px 0 -3px 0;'> Veuillez patienter...";
				objFacturation.updatePaiements();
			});

			//lance une recherche sur les commandes
			document.getElementById("rechercher_commandes").addEventListener("keypress", function (e) {

				//appuie sur "enter"
				if (e.keyCode == 13) {
					const _search_value = this.value || "all";
					location.href = _search_value;
					return false;
				}
			});

			//lance une recherche sur les commandes
			if (document.getElementById("bouton_rechercher_commandes")) {
				document.getElementById("bouton_rechercher_commandes").addEventListener("click", function () {
					const _search_value = document.getElementById("rechercher_commandes").value || "all";
					location.href = _search_value;
				});
			}

			//annule une recherche
			if (document.getElementById("bouton_cancel_rechercher_commandes")) {
				document.getElementById("bouton_cancel_rechercher_commandes").addEventListener("click", function () {
					location.href = "all";
				});
			}

			//privilégie le scoll horizontal dans le tableau
			if (document.getElementById("div_tableau_commandes")) {
				document.getElementById("div_tableau_commandes").addEventListener("wheel", function (e) {
					const delta = Math.sign(e.deltaY);
					document.getElementById("div_tableau_commandes").scrollLeft += (delta * 80);
					e.preventDefault();
				});
			}

			//highligth du tr au survol du numéro
			document.querySelectorAll('[name="tr_numero"], [name="tr_commande"]').forEach(function (tr) {
				var _k = tr.id.split("_")[1];
				tr.addEventListener("mouseover", function () {
					document.getElementById("tr_" + _k).style.backgroundColor = "#f5f5f5";
				});
				tr.addEventListener("mouseout", function () {
					document.getElementById("tr_" + _k).style.backgroundColor = "white";
				});
			});

			//rend le tableau sortable
			objCommons.sortTable(document.getElementById("tableau_commandes"));
		});
	}
	/**
	 * Page avec les payeurs
	 */
	facturationPayeurs() {

		//initialise le menu
		objMenu.facturation();

		//importe les modules
		Promise.all([
			import('./payeur'),
			import('./messages')
		]).then(([Payeur, Messages]) => {

			//créer un nouvel objet
			window.objPayeur = new Payeur.default();
			window.objMessages = new Messages.default();

			//filtre les payeurs en fonction du sifac
			document.getElementById("sifac_payeur").addEventListener("change", function () {
				const _search_value = document.getElementById("rechercher_payeurs").value || "all";
				location.href = "../../1/" + this.value + "/" + _search_value;
			});

			//renvoie a la page sélectionnée
			document.getElementById("num_page").addEventListener("change", function () {
				const _search_value = document.getElementById("rechercher_payeurs").value || "all";
				location.href = "../../" + this.value + "/" + document.getElementById("sifac_payeur").value + "/" + _search_value;
			});

			//lance une recherche sur les payeurs
			document.getElementById("rechercher_payeurs").addEventListener("keypress", function (e) {

				//appuie sur "enter"
				if (e.keyCode == 13) {
					const _search_value = this.value || "all";
					location.href = _search_value;
					return false;
				}
			});

			//lance une recherche sur les payeurs
			if (document.getElementById("bouton_rechercher_payeurs")) {
				document.getElementById("bouton_rechercher_payeurs").addEventListener("click", function () {
					const _search_value = document.getElementById("rechercher_payeurs").value || "all";
					location.href = _search_value;
				});
			}

			//annule une recherche
			if (document.getElementById("bouton_cancel_rechercher_payeurs")) {
				document.getElementById("bouton_cancel_rechercher_payeurs").addEventListener("click", function () {
					location.href = "all";
				});
			}

			//boucle pour chaque formulaire de la page
			if (document.getElementById("tableau_payeurs")) {
				document.getElementById("tableau_payeurs").querySelectorAll("tbody tr").forEach(function (tr) {
					var _k = tr.id.split("_")[1];

					//prevalide
					if (document.getElementById("td_" + _k + "_etat")) {
						document.getElementById("td_" + _k + "_etat").addEventListener("click", function () {
							objPayeur.prevalide(_k);
						});
					}
				});
			}

			//rend le tableau sortable
			objCommons.sortTable(document.getElementById("tableau_payeurs"));
		});
	}
	/**
	 * Page avec le résumé des commandes
	 */
	facturationResume() {

		//initialise le menu
		objMenu.facturation();

		//importe les modules
		Promise.all([
			import('./facturation'),
			import('./graphiques')
		]).then(([Facturation, Graphiques]) => {

			//créer un nouvel objet
			window.objFacturation = new Facturation.default();
			window.objGraphiques = new Graphiques.default();

			//renvoie a la page de l'année sélectionnée
			if (document.getElementById("annee_commande")) {
				document.getElementById("annee_commande").addEventListener("change", function () {
					location.href = this.value;
				});
			}

			//affichage de l'onglet
			document.querySelectorAll(".fond_onglets div").forEach(function (element) {
				element.addEventListener("click", function () {
					objCommons.switchOnglet(element.id);

					//affiche le graphique
					switch (element.id) {
						case  "commandes_30_jours":
							objGraphiques.commandes30Jours();
							break;
						case  "licences_types":
							objGraphiques.stackedChart("chart_licences_types", "../graphique_licences_types/scolaire/true");
							break;
						case  "licences_types_civile":
							objGraphiques.stackedChart("chart_licences_types_civile", "../graphique_licences_types/civile/true");
							break;
						case  "licences_renouvellements":
							objGraphiques.stackedChart("chart_licences_renouvellements", "../graphique_licences_renouvellements");
							break;
						case  "licences_paquets":
							objGraphiques.stackedChart("chart_licences_paquets", "../graphique_licences_paquets");
							break;
					}
				});
			});

			//ouvre le dialog de nouvelle dépense
			if (document.getElementById("bouton_nouvelle_depense")) {
				document.getElementById("bouton_nouvelle_depense").addEventListener("click", function () {
					objFacturation.dialogNouvelleDepense();
				});
			} else {
				//checkbox licences type
				document.getElementById("checkbox_licences_types").addEventListener("change", function () {
					const chart = chartInstances["chart_licences_types"];
					if (chart) {
						chart.destroy();
						delete chartInstances["chart_licences_types"];
					}

					//si c'est coché on relance la fonction
					if (this.checked) {
						objGraphiques.stackedChart("chart_licences_types", "../graphique_licences_types/scolaire/true");
					} else {
						objGraphiques.stackedChart("chart_licences_types", "../graphique_licences_types/scolaire/false");
					}
				});

				//checkbox licences type
				document.getElementById("checkbox_licences_types_civile").addEventListener("change", function () {
					const chart = chartInstances["chart_licences_types_civile"];
					if (chart) {
						chart.destroy();
						delete chartInstances["chart_licences_types_civile"];
					}

					//si c'est coché on relance la fonction
					if (this.checked) {
						objGraphiques.stackedChart("chart_licences_types_civile", "../graphique_licences_types/civile/true");
					} else {
						objGraphiques.stackedChart("chart_licences_types_civile", "../graphique_licences_types/civile/false");
					}
				});
			}

			//rend le tableau sortable
			if (document.getElementById("tableau_all_commandes")) {
				objCommons.sortTable(document.getElementById("tableau_all_commandes"));
		}
		});
	}
	/**
	 * Page d'accueil du forum
	 */
	forumDiscussions() {

		//lance une recherche sur le forum
		document.getElementById("rechercher_forum").addEventListener("keypress", function (e) {

			//appuie sur "enter"
			if (e.keyCode == 13 && this.value != "") {
				location.href = "rechercher/" + this.value + "/1";
				return false;
			}
		});

		//boucle pour chaque discussion
		document.querySelectorAll(".sujet_titre").forEach(function (discussion) {
			var _k = discussion.id.split("_")[1];

			//renvoie vers la discussion
			document.getElementById("lien_" + _k).addEventListener("click", function () {
				location.href = "liste_sujets/" + _k + "/1";
			});
		});
	}
	/**
	 * Page avec la liste des sujets d'une discussion
	 */
	forumListeSujets() {

		//initialise le menu
		objMenu.forum();

		//importe les modules
		import('./forum').then((Forum) => {

			//créer un nouvel objet
			window.objForum = new Forum.default();

			//renvoie a la page sélectionnée
			document.getElementById("num_page").addEventListener("change", function () {
				location.href = this.value;
			});

			//créer un nouveau sujet
			if (document.getElementById("bouton_nouveau_sujet")) {
				document.getElementById("bouton_nouveau_sujet").addEventListener("click", function () {
					objForum.openDialogSujet(null);
				});
			}

			//boucle pour chaque sujet
			document.getElementById("tableau_sujets").querySelectorAll("tbody tr").forEach(function (tr) {
				var _k = tr.id.split("_")[1];

				//renvoie vers le sujet
				if (document.getElementById("lien_" + _k)) {
					document.getElementById("lien_" + _k).addEventListener("click", function () {
						location.href = "../../sujet/" + _k;
					});
				}

				//modifie un sujet
				if (document.getElementById("modifier_" + _k)) {
					document.getElementById("modifier_" + _k).addEventListener("click", function () {
						objForum.openDialogSujet(_k);
					});
				}

				//supprime un sujet
				if (document.getElementById("supprimer_" + _k)) {
					document.getElementById("supprimer_" + _k).addEventListener("click", function () {
						objForum.dialogDeleteSujet(_k);
					});
				}
			});
		});
	}
	/**
	 * Page avec les résultats d'une recherche dans le forum
	 */
	forumRechercher() {

		//initialise le menu
		objMenu.forum();

		//importe les modules
		import('./forum').then((Forum) => {

			//créer un nouvel objet
			window.objForum = new Forum.default();

			//renvoie a la page sélectionnée
			document.getElementById("num_page").addEventListener("change", function () {
				location.href = this.value;
			});

			//lance une recherche sur les sujets
			document.getElementById("rechercher_forum").addEventListener("keypress", function (e) {

				//appuie sur "enter"
				if (e.keyCode == 13 && this.value != "") {
					location.href = "../" + this.value + "/1";
					return false;
				}
			});

			//boucle pour chaque sujet
			document.querySelectorAll("#tableau_sujets tbody tr").forEach(function (tr) {
				var _k = tr.id.split("_")[1];

				//renvoie vers le sujet
				if (document.getElementById("lien_" + _k)) {
					document.getElementById("lien_" + _k).addEventListener("click", function () {
						location.href = "../../sujet/" + _k;
					});
				}
			});
		});
	}
	/**
	 * Page d'un sujet du forum
	 */
	forumSujet() {

		//initialise le menu
		objMenu.forum();

		//importe les modules
		import('./forum').then((Forum) => {

			//créer un nouvel objet
			window.objForum = new Forum.default();

			//créer un nouveau post
			document.getElementById("bouton_nouveau_post").addEventListener("click", function () {
				objForum.openDialogPost(null);
			});

			//indique le sujet comme résolu
			if (document.getElementById("bouton_resolu")) {
				document.getElementById("bouton_resolu").addEventListener("click", function () {
					objForum.setResolu();
				});
			}

			//boucle pour chaque sujet
			document.querySelectorAll(".forum_posted_by").forEach(function (tr) {
				var _k = tr.id.split("_")[1];

				//modifie un post
				if (document.getElementById("modifier_" + _k)) {
					document.getElementById("modifier_" + _k).addEventListener("click", function () {
						objForum.openDialogPost(_k);
					});
				}

				//supprime un post
				if (document.getElementById("supprimer_" + _k)) {
					document.getElementById("supprimer_" + _k).addEventListener("click", function () {
						objForum.dialogDeletePost(_k);
					});
				}
			});
		});
	}
	/**
	 * Page de création d'un groupe
	 */
	groupesCreer() {

		//initialise le menu
		objMenu.groupes();

		//importe les modules
		import('./groupes').then((Groupes) => {

			//créer un nouvel objet
			window.objGroupes = new Groupes.default();

			//filtre les élèves
			document.getElementById("filtre_eleves").addEventListener("keyup", function () {
				objGroupes.filtreEleves();
			});

			//s'il y a des classes
			if (document.getElementById("classes_dndsource")) {

				//boucle pour chaque classe
				document.querySelectorAll(".dndClasse").forEach(function (classe) {
					var _k = classe.id.split("_")[1];

					//ouvre l'arborescence d'une classe
					document.getElementById("classe_" + _k).addEventListener("click", function () {
						var display = "inline-flex";

						//si le dossier est fermé
						if (document.getElementById("icone_" + _k).classList.contains("icone-folder_close")) {
							document.getElementById("icone_" + _k).classList.remove("icone-folder_close");
							document.getElementById("icone_" + _k).classList.add("icone-folder_open");
						} else {
							display = "none";
							document.getElementById("icone_" + _k).classList.remove("icone-folder_open");
							document.getElementById("icone_" + _k).classList.add("icone-folder_close");
						}

						//affiche cache les élèves
						document.querySelectorAll('#classes_dndsource [data-dndparent="' + _k + '"]').forEach(function (element) {

							//s'il faut cacher
							if (display == "none") {
								element.style.setProperty("display", "none", "important");
							} else if (document.getElementById("filtre_eleves").value != "") {
								objGroupes.filtreEleves();
							} else {
								element.style.display = display;
							}
						});


						//scroll jusqu'à l'élément
						var topPos = this.offsetTop - 150;
						document.getElementById("classes_scroll").scrollTop = topPos;
					});
				});

				//initialise le Dnd des élèves
				objGroupes.initDnd();

				//boucle pour chaque auteurs
				document.querySelectorAll(".auteurs_rubrique_items").forEach(function (auteur) {

					//action sur le click des auteurs
					auteur.addEventListener("click", function (e) {
						e.preventDefault();
						objCommons.showAuteurs(auteur.id.split("_")[1]);
					});
				});

				//valide la deuxième etape
				document.getElementById("valide").addEventListener("click", function () {
					objGroupes.create();
				});
			}
		});
	}
	/**
	 * Page avec la liste des groupes d'un enseignant
	 */
	groupesListe() {

		//importe les modules
		Promise.all([
			import('./groupes'),
			import('./tchat')
		]).then(([Groupes, Tchat]) => {

			//créer un nouvel objet
			window.objGroupes = new Groupes.default();
			window.objTchat = new Tchat.default();

			//inscription aux calibrations
			if (document.getElementById("participer_calibration")) {
				document.getElementById("participer_calibration").addEventListener("click", function () {

					//importe les modules
					import('./calibration').then((Calibration) => {
						window.objCalibration = new Calibration.default();
						objCalibration.participer();
					});
				});
			}

			//créer un nouveau groupe
			document.getElementById("bouton_creer").addEventListener("click", function () {
				objCommons.disableButton(this.id);
				location.href = "creer/nouveau";
			});

			//importer du GAR
			if (document.getElementById("bouton_importer_gar")) {
				document.getElementById("bouton_importer_gar").addEventListener("click", function () {
					objCommons.disableButton(this.id);
					objGroupes.importerGar();
				});
			}

			//boucle pour chaque groupe
			document.querySelectorAll(".div_rubrique").forEach(function (groupe) {
				var _k = groupe.id.split("_")[1];

				//envoie sur la page de calibrations
				if (document.getElementById("calibrations_" + _k)) {
					document.getElementById("calibrations_" + _k).addEventListener("click", function () {
						location.href = "../calibrations/seance/" + _k;
					});
				}

				//télécharge les cartes eleves
				if (document.getElementById("cartes_pdf_" + _k)) {
					document.getElementById("cartes_pdf_" + _k).addEventListener("click", function () {
						window.open("cartes_pdf/groupe/" + _k);
					});
				}

				//envoie sur la page de modification
				document.getElementById("modifier_" + _k).addEventListener("click", function () {
					location.href = "creer/" + _k;
				});

				//ouvre le dialog de confirmation de suppression
				document.getElementById("supprimer_" + _k).addEventListener("click", function () {
					objGroupes.dialogDelete(_k);
				});
			});

			//initialise le chat
			objTchat.init();
		});
	}
	/**
	 * Page de génération d'un devis
	 */
	inscriptionDevis() {

		//initialise le menu
		if (javascript_common_vars.statut != "false") {
			objMenu.licence();
		}

		//importe les modules
		import('./inscription').then((Inscription) => {

			//creer un nouvel objet
			window.objInscription = new Inscription.default();

			//génère un captcha
			objInscription.getCaptcha();

			//initialise les titlepanes
			objCommons.initTitlePanes();

			//génère le select pays
			objCommons.getPays(true, javascript_page_vars.id_pays).then(function () {

				//met a jour le prix total
				document.getElementById("nb_licences").addEventListener("change", function () {
					objCommons.updatePrix();
				});
			});

			//valide le formulaire des infos du devis
			document.getElementById("check_infos").addEventListener("click", function () {

				//si le formulaire est ok
				if (document.getElementById("infos_form").checkValidity() && $("#pays_etablissement").selectize()[0].selectize.getValue() != "") {

					//ouvre l'étape suivante
					objCommons.titlePaneSlide("infos", "nb_licences");
				} else {

					//affiche les champs invalides
					objCommons.highlightInvalid("infos_form");
				}
			});

			//valide le formulaire du nb de licences
			document.getElementById("check_nb_licences").addEventListener("click", function () {

				//si le formulaire est ok
				if (document.getElementById("nb_licences_form").checkValidity()) {

					//ouvre l'étape suivante
					objCommons.titlePaneSlide("nb_licences", "finalisation");
				} else {

					//affiche les champs invalides
					objCommons.highlightInvalid("nb_licences_form");
				}
			});

			//rafraichi l'image captcha
			document.getElementById("refresh_captcha").addEventListener("click", function () {
				objInscription.getCaptcha();
			});

			//finalise l'inscription
			document.getElementById("check_finalisation").addEventListener("click", function () {
				objInscription.createDevis();
			});

			//action sur le bouton retour
			document.getElementById("retour").addEventListener("click", function () {
				objCommons.disableButton(this.id);
				if (javascript_common_vars.statut == "false") {
					location.href = javascript_common_vars.url;
				} else {
					location.href = "gerer/last";
				}
			});
		});
	}
	/**
	 * Page d'inscription d'un établissement
	 */
	inscriptionEtablissement() {

		//initialise le menu
		objMenu.presentation("inscription");

		//importe les modules
		import('./inscription').then((Inscription) => {

			//creer un nouvel objet
			window.objInscription = new Inscription.default();

			//génère un captcha
			objInscription.getCaptcha();

			//indique le code uai dans le champ s'il y en a un
			if (javascript_page_vars.code_uai_gar) {

				//génère un dialog pour la confirmation d'inscription
				objInscription.dialogGar();
			}

			//initialise les titlepanes
			objCommons.initTitlePanes();

			//génère le select communes
			objCommons.getCommunes(true).then(function () {

				//action sur le bouton etablissement_avec_uai
				document.getElementById("etablissement_avec_uai").addEventListener("change", function () {
					if (this.checked == true) {

						//champ pays = France
						$("#pays_etablissement").selectize()[0].selectize.setValue(64);

						//affiche le champ code_uai
						document.getElementById("tr_code_uai").style.display = "table-row";
						document.getElementById("tr_pays").style.display = "none";

						//rcode_uai required
						document.getElementById("code_uai").required = true;

						//recupère l'étab
						objCommons.getEtablissement();
					}
				});

				//action sur le bouton etablissement_sans_uai
				document.getElementById("etablissement_sans_uai").addEventListener("change", function () {
					if (this.checked == true) {

						//affiche le champ pays
						document.getElementById("tr_code_uai").style.display = "none";
						document.getElementById("tr_pays").style.display = "table-row";

						//active les champs
						document.getElementById("type_etablissement").disabled = false;
						document.getElementById("nom_etablissement").disabled = false;
						document.getElementById("adresse_etablissement").disabled = false;
						$("#commune_etablissement").selectize()[0].selectize.enable();

						//rend les champs required
						document.getElementById("code_uai").value = "";
						document.getElementById("code_uai").required = false;
					}
				});

				//action sur le code uai
				document.getElementById("code_uai").addEventListener("keyup", function (e) {

					//ignore le shift
					if (e.keyCode != 16) {
						objCommons.getEtablissement();
					}
				});

				//génère le select pays
				objCommons.getPays(false, 64);

				//indique le code uai dans le champ s'il y en a un
				if (javascript_page_vars.code_uai_gar) {
					document.getElementById("code_uai").value = javascript_page_vars.code_uai_gar;
					objCommons.getEtablissement();
				}

				//valide le formulaire des infos persos
				document.getElementById("check_infos").addEventListener("click", function () {
					objInscription.checkInfos();
				});

				//importe le fichier
				document.getElementById("fichier_justificatif").addEventListener("change", function () {
					objCommons.checkJustificatif("pedagogie_form_errors");
				});

				//valide le formulaire du projet pédagogique
				document.getElementById("check_pegagogie").addEventListener("click", function () {
					objInscription.checkPedagogie();
				});

				//rafraichi l'image captcha
				document.getElementById("refresh_captcha").addEventListener("click", function () {
					objInscription.getCaptcha();
				});

				//finalise l'inscription
				document.getElementById("check_finalisation").addEventListener("click", function () {
					objInscription.checkFinalisation();
				});
			});
		});
	}
	/**
	 * Page d'inscription d'un orthophoniste
	 */
	inscriptionOrthophoniste() {

		//initialise le menu (menu tester gratuitement)
		objMenu.presentation("test");

		//importe les modules
		import('./inscription').then((Inscription) => {

			//creer un nouvel objet
			window.objInscription = new Inscription.default();

			//génère un captcha
			objInscription.getCaptcha();

			//initialise les titlepanes
			objCommons.initTitlePanes();

			//génère le select communes
			objCommons.getCommunes(false).then(function () {

				//action sur le bouton etablissement_avec_uai
				document.getElementById("etablissement_avec_uai").addEventListener("change", function () {
					if (this.checked == true) {

						//champ pays = France
						$("#pays_etablissement").selectize()[0].selectize.setValue(64);

						//affiche le champ siret
						document.getElementById("tr_siret").style.display = "table-row";
						document.getElementById("tr_pays").style.display = "none";

						//siret required
						document.getElementById("siret").required = true;
					}
				});

				//action sur le bouton etablissement_sans_uai
				document.getElementById("etablissement_sans_uai").addEventListener("change", function () {
					if (this.checked == true) {

						//affiche le champ pays
						document.getElementById("tr_siret").style.display = "none";
						document.getElementById("tr_pays").style.display = "table-row";

						//rend les champs required
						document.getElementById("siret").value = "";
						document.getElementById("siret").required = false;
					}
				});

				//génère le select pays
				objCommons.getPays(false, 64);

				//valide le formulaire des infos persos
				document.getElementById("check_infos").addEventListener("click", function () {
					objInscription.checkInfos();
				});

				//importe le fichier
				document.getElementById("fichier_justificatif").addEventListener("change", function () {
					objCommons.checkJustificatif("infos_form_errors");
				});

				//rafraichi l'image captcha
				document.getElementById("refresh_captcha").addEventListener("click", function () {
					objInscription.getCaptcha();
				});

				//finalise l'inscription
				document.getElementById("check_finalisation").addEventListener("click", function () {
					objInscription.checkFinalisation();
				});
			});
		});
	}
	/**
	 * Page d'inscription d'un organisme public
	 */
	inscriptionOrganismePublic() {

		//importe les modules
		import('./inscription').then((Inscription) => {

			//creer un nouvel objet
			window.objInscription = new Inscription.default();

			//génère un captcha
			objInscription.getCaptcha();

			//initialise les titlepanes
			objCommons.initTitlePanes();

			//génère le select communes
			objCommons.getCommunes(false).then(function () {

				//génère le select pays
				objCommons.getPays(false, 64);

				//valide le formulaire des infos persos
				document.getElementById("check_infos").addEventListener("click", function () {
					objInscription.checkInfos();
				});

				//rafraichi l'image captcha
				document.getElementById("refresh_captcha").addEventListener("click", function () {
					objInscription.getCaptcha();
				});

				//finalise l'inscription
				document.getElementById("check_finalisation").addEventListener("click", function () {
					objInscription.checkFinalisation();
				});
			});
		});
	}
	/**
	 * Page de confirmation d'une inscription
	 */
	inscriptionTerminee() {

		//action sur le bouton retour
		document.getElementById("accueil").addEventListener("click", function () {
			objCommons.disableButton(this.id);
			location.href = javascript_common_vars.url;
		});
	}
	/**
	 * Page de création d'un compte test
	 */
	inscriptionTest() {

		//initialise le menu
		objMenu.presentation("test");

		//importe les modules
		import('./inscription').then((Inscription) => {

			//creer un nouvel objet
			window.objInscription = new Inscription.default();

			//génère un captcha
			objInscription.getCaptcha();

			//rafraichi l'image captcha
			document.getElementById("refresh_captcha").addEventListener("click", function () {
				objInscription.getCaptcha();
			});

			//valide la création du compte test
			document.getElementById("valide_compte_test").addEventListener("click", function () {
				objInscription.checkFinalisation();
			});
		});
	}
	/**
	 * Page de création d'un item
	 */
	itemsCreation() {

		//initialise le menu
		objMenu.items();

		//importe les modules
		import('./item').then((Item) => {

			//créer un nouvel objet
			window.objItem = new Item.default();

			//change le module
			document.getElementById("item_module").addEventListener("change", function () {
				location.href = this.value;
			});

			//change le type d'exercice
			document.getElementById("item_type").addEventListener("change", function () {
				objItem.getType();
			});

			//recupere la regle et l'item exemple
			objItem.getType();

			//si le champ mot existe
			if (document.getElementById("mot")) {
				objItem.getMots();
			}

			//initialise le module tinymce
			objItem.initTinymce();

			//action sur les boutons d'ajout de réponses
			if (document.getElementById("question_preparatoire_add_bonne_reponse")) {
				document.getElementById("question_preparatoire_add_bonne_reponse").addEventListener("click", function () {
					objItem.addReponse("question_preparatoire", "bonne_reponse");
				});
			}
			if (document.getElementById("question_preparatoire_add_mauvaise_reponse")) {
				document.getElementById("question_preparatoire_add_mauvaise_reponse").addEventListener("click", function () {
					objItem.addReponse("question_preparatoire", "mauvaise_reponse");
				});
			}

			//action sur les boutons d'ajout de réponses
			if (document.getElementById("question_principale_add_bonne_reponse")) {
				document.getElementById("question_principale_add_bonne_reponse").addEventListener("click", function () {
					objItem.addReponse("question_principale", "bonne_reponse");
				});
			}
			if (document.getElementById("question_principale_add_mauvaise_reponse")) {
				document.getElementById("question_principale_add_mauvaise_reponse").addEventListener("click", function () {
					objItem.addReponse("question_principale", "mauvaise_reponse");
				});
			}

			//valide la création d'un item
			document.getElementById("valider").addEventListener("click", function () {
				objItem.create();
			});

			//change la valeur du select item_type
			if (javascript_page_vars.value_item_type) {
				document.getElementById("item_type").value = javascript_page_vars.value_item_type;
			}
		});
	}
	/**
	 * Page de confirmation de création d'un item
	 */
	itemsCreationOk() {

		//initialise le menu
		objMenu.items();
	}
	/**
	 * Page de duplication d'un item
	 */
	itemsDuplication() {

		//initialise le menu
		objMenu.items();

		//importe les modules
		import('./item').then((Item) => {

			//créer un nouvel objet
			window.objItem = new Item.default();

			//change le type d'exercice
			document.getElementById("item_type").addEventListener("change", function () {
				objItem.getType();
			});

			//recupere la regle et l'item exemple
			objItem.getType();

			//si le champ mot existe
			if (document.getElementById("mot")) {
				objItem.getMots();
			}

			//initialise le module tinymce
			objItem.initTinymce();

			//action sur les boutons d'ajout de réponses
			if (document.getElementById("question_preparatoire_add_bonne_reponse")) {
				document.getElementById("question_preparatoire_add_bonne_reponse").addEventListener("click", function () {
					objItem.addReponse("question_preparatoire", "bonne_reponse");
				});
			}
			if (document.getElementById("question_preparatoire_add_mauvaise_reponse")) {
				document.getElementById("question_preparatoire_add_mauvaise_reponse").addEventListener("click", function () {
					objItem.addReponse("question_preparatoire", "mauvaise_reponse");
				});
			}

			//action sur les boutons d'ajout de réponses
			if (document.getElementById("question_principale_add_bonne_reponse")) {
				document.getElementById("question_principale_add_bonne_reponse").addEventListener("click", function () {
					objItem.addReponse("question_principale", "bonne_reponse");
				});
			}
			if (document.getElementById("question_principale_add_mauvaise_reponse")) {
				document.getElementById("question_principale_add_mauvaise_reponse").addEventListener("click", function () {
					objItem.addReponse("question_principale", "mauvaise_reponse");
				});
			}

			//affiche les mots en double
			objItem.duplicateWords(javascript_page_vars.mots_communs);

			//action sur le bouton comparer
			document.getElementById("comparer").addEventListener("click", function () {

				//compare l'enonce
				objItem.compareStrings(document.getElementById("enonce_source").innerHTML, tinymce.get("enonce").getContent(), "enonce_diff");

				//question_preparatoire
				if (document.getElementById("question_preparatoire_source")) {
					document.querySelectorAll("#div_question_preparatoire_input .diff_dupliquer_item").forEach(function (element) {
						var champ = element.id.slice(0, -5);
						objItem.compareStrings(document.getElementById(champ + "_source").innerHTML, document.getElementById(champ).value, element.id);
					});
				}

				//question principale
				document.querySelectorAll("#div_question_principale_input .diff_dupliquer_item").forEach(function (element) {
					var champ = element.id.slice(0, -5);
					objItem.compareStrings(document.getElementById(champ + "_source").innerHTML, document.getElementById(champ).value, element.id);
				});

				//compare la justification
				objItem.compareStrings(document.getElementById("justification_source").innerHTML, tinymce.get("justification").getContent(), "justification_diff");

				//affiche les mots en double
				objItem.duplicateWords(javascript_page_vars.mots_communs);
			});

			//valide la création de l'exercice
			document.getElementById("valider").addEventListener("click", function () {
				objItem.create();
			});
		});
	}
	/**
	 * Page avec la liste des items
	 */
	itemsListe() {

		//initialise le menu
		objMenu.items();

		//importe les modules
		import('./item').then((Item) => {

			//créer un nouvel objet
			window.objItem = new Item.default();

			//change le module
			document.getElementById("item_module").addEventListener("change", function () {
				location.href = "../../" + this.value + "/all/1";
			});

			//renvoie a la page sélectionnée
			document.getElementById("num_page").addEventListener("change", function () {
				document.getElementById("search_plus_form").action = "../" + document.getElementById("etat_items").value + "/" + this.value;
				document.getElementById("search_plus_form").submit();
			});

			//filtre les items en fonction du type
			document.getElementById("etat_items").addEventListener("change", function () {
				location.href = "../" + this.value + "/1";
			});

			//recherche avancée
			if (document.getElementById("bouton_search_plus")) {
				document.getElementById("bouton_search_plus").addEventListener("click", function () {
					objItem.dialogSearch();
				});
			}

			//valide la recherche
			if (document.getElementById("dialog_search_plus_valider")) {
				document.getElementById("dialog_search_plus_valider").addEventListener("click", function () {
					document.getElementById("search_plus_form").action = "../" + document.getElementById("etat_items").value + "/1";
					document.getElementById("search_plus_form").submit();
				});
			}

			//ferme le dialog
			if (document.getElementById("dialog_search_plus_annuler")) {
				document.getElementById("dialog_search_plus_annuler").addEventListener("click", function () {
					objDialog.hide();
				});
			}

			//annule une recherche
			if (document.getElementById("bouton_cancel_search")) {
				document.getElementById("bouton_cancel_search").addEventListener("click", function () {
					location.href = "1";
				});
			}
		});
	}
	/**
	 * Page avec le résumé des items
	 */
	itemsResume() {

		//initialise le menu
		objMenu.items();

		//importe les modules
		Promise.all([
			import('./item'),
			import('./graphiques'),
			import('./resultats')
		]).then(([Item, Graphiques, Resultats]) => {

			//créer un nouvel objet
			window.objItem = new Item.default();
			window.objGraphiques = new Graphiques.default();
			window.objResultats = new Resultats.default();

			//change le module
			document.getElementById("item_module").addEventListener("change", function () {
				location.href = this.value;
			});

			//affichage de l'onglet
			document.querySelectorAll(".fond_onglets div").forEach(function (element) {
				element.addEventListener("click", function () {
					objCommons.switchOnglet(element.id);

					//affiche le graphique
					switch (element.id) {
						case  "repartition_items":
							objGraphiques.repartitionItems();
							break;
						case  "repartition_types":
							objGraphiques.repartitionTypes();
							break;
					}
				});
			});

			//action sur le menu reperes
			document.getElementById("reperes_graphiques").addEventListener("click", function () {
				objResultats.showRepere(this.value);
			});

			//lance une recherche sur les types
			document.getElementById("rechercher_type").addEventListener("keyup", function (e) {
				var _search_value = this.value;
				document.querySelectorAll(".grand_type_resume .div_titre b").forEach(function (element) {
					if (element.innerHTML.includes(_search_value)) {
						element.parentNode.parentNode.style.display = "block";
					} else {
						element.parentNode.parentNode.style.display = "none";
					}
				});
			});

			//ouvre les titlepane
			document.querySelectorAll(".grand_type_resume .div_titre").forEach(function (element) {
				element.addEventListener("click", function () {
					var content_el = document.getElementById(this.id + "_content");
					if (content_el.style.display == "none") {
						content_el.style.display = "block";
						document.getElementById(this.id + "_resume").style.display = "none";
					} else {
						content_el.style.display = "none";
						document.getElementById(this.id + "_resume").style.display = "block";
					}
				});
			});

			//click sur le bouton tout ouvrir
			document.getElementById("bouton_tout_ouvrir").addEventListener("click", function () {
				document.querySelectorAll(".grand_type_resume .div_titre").forEach(function (element) {
					var content_el = document.getElementById(element.id + "_content");
					if (content_el.style.display == "none") {
						content_el.style.display = "block";
						document.getElementById(element.id + "_resume").style.display = "none";
					} else {
						content_el.style.display = "none";
						document.getElementById(element.id + "_resume").style.display = "block";
					}
				});
			});

			//lien vers la création d'un item
			document.querySelectorAll('[name="creer_exercice"]').forEach(function (element) {
				element.addEventListener("click", function () {
					document.getElementById("id_item_type").value = this.id.split("_")[2];
					document.getElementById("go_to_creation").submit();
				});
			});
		});
	}
	/**
	 * Page de révision des items
	 */
	itemsRevision() {

		//initialise le menu
		objMenu.items();

		//création de variables pour la page
		javascript_page_vars.last_reponse_maj = "question_principale_mauvaise_reponse_0";

		//importe les modules
		import('./item').then((Item) => {

			//créer un nouvel objet
			window.objItem = new Item.default();

			//boucle pour chaque clone
			if (document.getElementById("clones")) {
				document.getElementById("clones").querySelectorAll("a").forEach(function (clone) {
					clone.addEventListener("click", function () {
						location.href = "../revision/" + this.id.split("_")[1];
					});
				});
			}

			//action sur le champ etat
			document.getElementById("etat").addEventListener("change", function () {
				objItem.update("etat", this.value);
			});

			//action sur l'item vue eleve
			if (document.getElementById("item_vue_eleve")) {
				document.getElementById("div_contenu_item").addEventListener("click", function () {
					if (javascript_page_vars.autorisations == "true") {
						document.getElementById("item_vue_eleve").style.display = "none";
						document.getElementById("item_champs").style.display = "block";
					}
				});
			}

			//action sur le bouton retour item vue eleve
			if (document.getElementById("retour_vue_eleve")) {
				document.getElementById("retour_vue_eleve").addEventListener("click", function () {
					objCommons.disableButton(this.id);
					location.reload();
				});
			}

			//initialise le module tinymce
			objItem.initTinymce();

			//action sur le champ item_type
			document.getElementById("item_type").addEventListener("change", function () {
				objItem.update("id_item_type", this.value);
				objItem.getType();
			});

			//recupere la regle et l'item exemple
			objItem.getType();

			//action sur le champ mot
			if (document.getElementById("mot")) {
				objItem.getMots();
			}

			//sélection des aides à afficher
			if (document.getElementById("regle_checkbox")) {

				//recupère toutes les cases a cocher
				var checkboxs = document.querySelectorAll('[name="affichage_aides"]');
				[].forEach.call(checkboxs, function (checkbox) {

					//action sur le bouton
					checkbox.addEventListener("change", function () {
						var checked = this.checked;
						var id = this.id.split("_");

						//affiche le tableau
						document.getElementById("regles_procedures").style.display = "table";

						//affiche les aides demandées
						document.getElementById(id[0]).style.display = checked ? "table-row" : "none";
					});
				});

				//modifie les aides affichées par défaut 
				document.querySelectorAll("#regles_procedures .select_reponse_type").forEach(function (select) {

					//action sur le select
					select.addEventListener("change", function () {
						var ids_types = [];

						//récupère la liste des types sélectionnés
						document.querySelectorAll("#regles_procedures select").forEach(function (select) {
							ids_types.push(select.value);
						});

						//met à jour l'item
						objItem.update("id_item_type_aides", JSON.stringify(ids_types));
					});
				});

				//bouton modifier les aides 
				document.querySelectorAll('[name="modifier_icone"]').forEach(function (icone) {
					var id = icone.id.split("_");

					//action sur l'icône
					icone.addEventListener("click", function () {

						//cache le texte
						document.querySelectorAll('[name="aide_content_' + id[1] + '"]').forEach(function (element) {
							element.style.display = "none";
						});

						//affiche les champs
						document.querySelectorAll('[name="aide_champ_' + id[1] + '"]').forEach(function (element) {
							element.style.display = "block";
						});

						//affiche les boutons
						document.getElementById("modifier_boutons").style.display = "table-row";
						document.getElementById("type_" + id[1] + "_boutons").style.display = "block";
					});
				});

				//action sur les boutons
				document.getElementById("regles_procedures").querySelectorAll("button").forEach(function (button) {
					var id = button.id.split("_");

					//bouton valide
					if (id[2] == "valide") {

						//evenement au clic
						button.addEventListener("click", function () {
							javascript_page_vars.id_type = id[1];
							objItem.valideUpdateType();
						});
					}

					//boutno cancel
					if (id[2] == "cancel") {

						//evenement au clic
						button.addEventListener("click", function () {

							//affiche le texte
							document.querySelectorAll('[name="aide_content_' + id[1] + '"]').forEach(function (element) {
								element.style.display = "block";
							});

							//cache les champs
							document.querySelectorAll('[name="aide_champ_' + id[1] + '"]').forEach(function (element) {
								element.style.display = "none";
							});

							//cache les boutons
							document.getElementById("modifier_boutons").style.display = "none";
							document.getElementById("type_" + id[1] + "_boutons").style.display = "none";
						});
					}
				});

				//reset des aides
				if (document.getElementById("reset_id_item_type_aides")) {
					document.getElementById("reset_id_item_type_aides").addEventListener("click", function () {
						objItem.update("id_item_type_aides", "");
					});
				}
			}

			//valide les modification sur l'énoncé
			if (document.getElementById("valide_update_enonce")) {
				document.getElementById("valide_update_enonce").addEventListener("click", function () {
					var content = tinymce.get("enonce").getContent();

					//s'il n'y a pas de mot bleu
					if (javascript_page_vars.module.mot_bleu == "true" && (content.search("#0000ff") == -1 && content.search("rgb(0, 0, 255)") == -1)) {
						document.getElementById("error_enonce").innerHTML = "Vous devez spécifier un mot bleu !";
					} else {
						objItem.update("enonce", content);
					}
				});
			}

			//action sur les boutons d'ajout de réponses
			if (document.getElementById("question_preparatoire_add_bonne_reponse")) {
				document.getElementById("question_preparatoire_add_bonne_reponse").addEventListener("click", function () {
					objItem.addReponse("question_preparatoire", "bonne_reponse");
				});
			}
			if (document.getElementById("question_preparatoire_add_mauvaise_reponse")) {
				document.getElementById("question_preparatoire_add_mauvaise_reponse").addEventListener("click", function () {
					objItem.addReponse("question_preparatoire", "mauvaise_reponse");
				});
			}

			//valide les modification sur la question preparatoire
			if (document.getElementById("valide_update_question_preparatoire")) {
				document.getElementById("valide_update_question_preparatoire").addEventListener("click", function () {
					objItem.update("question_preparatoire", "");
				});
			}

			//action sur les boutons d'ajout de réponses
			if (document.getElementById("question_principale_add_bonne_reponse")) {
				document.getElementById("question_principale_add_bonne_reponse").addEventListener("click", function () {
					objItem.addReponse("question_principale", "bonne_reponse");
				});
			}
			if (document.getElementById("question_principale_add_mauvaise_reponse")) {
				document.getElementById("question_principale_add_mauvaise_reponse").addEventListener("click", function () {
					objItem.addReponse("question_principale", "mauvaise_reponse");
				});
			}

			//valide les modification sur la question principale
			if (document.getElementById("valide_update_question_principale")) {
				document.getElementById("valide_update_question_principale").addEventListener("click", function () {
					objItem.update("question_principale", "");
				});
			}

			//valide les modification sur la justification
			if (document.getElementById("valide_update_justification")) {
				document.getElementById("valide_update_justification").addEventListener("click", function () {
					var content = tinymce.get("justification").getContent();
					objItem.update("justification", content);
				});
			}

			//action sur le bouton bouton_enregistrer_commentaire
			document.getElementById("bouton_enregistrer_commentaire").addEventListener("click", function () {
				objCommons.disableButton(this.id);
				objItem.newCommentaire(javascript_page_vars.id_item, document.getElementById("nouveau_commentaire").value, true);
			});

			//action sur le champ comment
			document.getElementById("nouveau_commentaire").addEventListener("keypress", function (e) {

				//appuie sur "enter"
				if (e.keyCode == 13) {
					objItem.newCommentaire(javascript_page_vars.id_item, this.value, true);
					this.value = "";
					return false;
				}
			});

			//clic sur une étoile
			document.querySelectorAll('[name="rate"]').forEach(function (element) {
				element.addEventListener("click", function () {
					objItem.rate(this.value);
				});
			});

			//action sur le bouton precedent
			document.getElementById("precedent").addEventListener("click", function () {
				objCommons.disableButton(this.id);
				objItem.get("prev");
			});

			//génère le select mots
			$.getJSON(javascript_common_vars.url + "items/get_store/ids_items", function (data) {
				var mySelect = $("#num_item").selectize({
					plugins: ["restore_on_backspace"],
					delimiter: " - ",
					persist: false,
					maxItems: 1,
					valueField: "id",
					labelField: "id",
					searchField: ["id"],
					options: data,
					onChange: function (value) {
						var item = this.options[value];
						if (item && item.id != javascript_page_vars.id_item) {
							location.href = item.id;
						}
					}
				});

				//valeur par défaut
				mySelect[0].selectize.setValue(javascript_page_vars.id_item);
			});

			//action sur le bouton suivant
			document.getElementById("suivant").addEventListener("click", function () {
				objCommons.disableButton(this.id);
				objItem.get("next");
			});

			//action sur le bouton dupliquer
			if (document.getElementById("bouton_dupliquer")) {
				document.getElementById("bouton_dupliquer").addEventListener("click", function () {
					objCommons.disableButton(this.id);
					location.href = "../duplication/" + javascript_page_vars.id_item;
				});
			}

			//action sur le bouton supprimer
			if (document.getElementById("delete_item")) {
				document.getElementById("delete_item").addEventListener("click", function () {
					objItem.dialogDelete();
				});
			}

			//cache la div de loading
			if (document.getElementById("div_contenu_item_loading")) {
				document.getElementById("div_contenu_item_loading").style.display = "none";
			}
		});
	}
	/**
	 * Page de confirmation d'une commande
	 */
	licenceCommandeOk() {

		//initialise le menu
		objMenu.licence();

		//retour à la liste des commande
		document.getElementById("retour").addEventListener("click", function () {
			objCommons.disableButton(this.id);
			location.href = "../gerer/last";
		});
	}
	/**
	 * Page de commande de licences
	 */
	licenceCommander() {

		//initialise le menu
		objMenu.licence();

		//importe les modules
		import('./commandes').then((Commandes) => {

			//creer un nouvel objet
			window.objCommandes = new Commandes.default();

			//initialise les titlepanes
			objCommons.initTitlePanes();

			//valide le formulaire de nombre de licences
			document.getElementById("check_nb_licences").addEventListener("click", function () {
				objCommandes.checkLicences();
			});

			//génère le select communes
			objCommons.getCommunes(true, javascript_page_vars.id_commune).then(function () {

				//action sur le bouton etablissement_avec_uai
				document.getElementById("etablissement_avec_uai").addEventListener("change", function () {
					if (this.checked == true) {

						//champ pays = France
						$("#pays_etablissement").selectize()[0].selectize.setValue(javascript_page_vars.id_pays);

						//affiche le champ code_uai
						if (document.getElementById("code_uai")) {
							document.getElementById("tr_code_uai").style.display = "table-row";
							document.getElementById("tr_pays").style.display = "none";

							//rcode_uai required
							document.getElementById("code_uai").value = javascript_page_vars.code_uai;
							document.getElementById("code_uai").required = true;
						}

						//siret pas required
						document.getElementById("td_siret").style.display = "none";
						document.getElementById("siret_payeur").value = "";
						document.getElementById("siret_payeur").required = false;

						//recupère l'étab
						objCommons.getEtablissement();
					}
				});

				//action sur le bouton etablissement_sans_uai
				document.getElementById("etablissement_sans_uai").addEventListener("change", function () {
					if (this.checked == true) {

						//affiche le champ pays
						if (document.getElementById("code_uai")) {
							document.getElementById("code_uai").value = "";
							document.getElementById("code_uai").required = false;
							document.getElementById("tr_code_uai").style.display = "none";
						}
						document.getElementById("tr_pays").style.display = "table-row";

						//active les champs
						document.getElementById("type_etablissement").disabled = false;
						document.getElementById("nom_etablissement").disabled = false;
						document.getElementById("adresse_etablissement").disabled = false;
						if (javascript_page_vars.id_commune != "99999") {
							$("#commune_etablissement").selectize()[0].selectize.enable();
						} else {
							$("#commune_etablissement").selectize()[0].selectize.disable();
						}
					}
				});

				//action sur le code uai
				if (document.getElementById("code_uai")) {
					document.getElementById("code_uai").addEventListener("keyup", function (e) {

						//ignore le shift
						if (e.keyCode != 16) {
							objCommons.getEtablissement();
						}
					});
				}

				//génère le select pays
				objCommons.getPays(true, javascript_page_vars.id_pays).then(function () {

					//met a jour le prix total
					document.getElementById("nb_licences").addEventListener("change", function () {
						objCommons.updatePrix();
					});

					//si c'est avec code UAI
					if (javascript_page_vars.code_uai != "") {
						document.getElementById("etablissement_avec_uai").checked = true;
						objCommons.triggerEvent(document.getElementById("etablissement_avec_uai"), "change");
					} else {
						document.getElementById("etablissement_sans_uai").checked = true;
						objCommons.triggerEvent(document.getElementById("etablissement_sans_uai"), "change");
					}
				});

				//au moment du choix du payeur
				if (document.getElementById("type_etablissement")) {
					document.getElementById("type_etablissement").addEventListener("change", function () {
						objCommandes.updatePayeurForm(this.value);
					});
				}

				//valide le formulaire de l'organisme payeur
				document.getElementById("check_payeur").addEventListener("click", function () {
					objCommandes.checkPayeur();
				});

				//validation du formulaire de nouvelle commande
				document.getElementById("valide_new_commande").addEventListener("click", function () {
					if (javascript_common_vars.statut == "orthophoniste") {
						objCommandes.nouvelleCommande();
					} else {
						objCommandes.confirmPayeur();
					}
				});

				//annulation du formulaire de nouvelle commande
				document.getElementById("cancel_new_commande").addEventListener("click", function () {
					objCommons.disableButton(this.id);
					if (javascript_common_vars.statut == 'facturation' || javascript_common_vars.statut == 'administrateur') {
						location.href = "../liste";
					} else {
						if (javascript_page_vars.id_commande) {
							location.href = "../gerer";
						} else {
							location.href = "gerer";
						}
					}
				});
			});
		});
	}
	/**
	 * Page de sélection des licences pour le GAR
	 */
	licenceGar() {

		//initialise le menu
		objMenu.licence();

		//importe les modules
		import('./gar').then((Gar) => {

			//creer un nouvel objet
			window.objGar = new Gar.default();

			//validation du formulaire
			document.getElementById("valide_licences_gar").addEventListener("click", function () {
				if (document.getElementById("nb_licences_gar").checkValidity()) {
					objGar.dialogConfirm();
				} else {
					objCommons.isInvalid("nb_licences_gar");
				}
			});

			//annulation du formulaire
			document.getElementById("cancel_licences_gar").addEventListener("click", function () {
				objCommons.disableButton(this.id);
				location.href = "gerer";
			});

			//retour
			document.getElementById("retour").addEventListener("click", function () {
				objCommons.disableButton(this.id);
				location.href = "gerer";
			});
		});
	}
	/**
	 * Page de gestion de la licence
	 */
	licenceGerer() {

		//importe les modules
		Promise.all([
			import('./commandes'),
			import('./transferts')
		]).then(([Commandes, Transferts]) => {

			//creer un nouvel objet
			window.objCommandes = new Commandes.default();
			window.objTransferts = new Transferts.default();

			//5 dernières ou toutes ?
			document.getElementById("liste_commande").addEventListener("change", function () {
				location.href = this.value;
			});

			//5 derniers ou tous ?
			if (document.getElementById("liste_transferts")) {
				document.getElementById("liste_transferts").addEventListener("change", function () {
					location.href = this.value;
				});
			}

			//ouvre le dialog de création d'un devis
			document.getElementById("bouton_creer_devis").addEventListener("click", function () {
				objCommons.disableButton(this.id);
				location.href = "../devis";
			});

			//affiche le formulaire de nouvelle commande
			document.getElementById("bouton_nouvelle_commande").addEventListener("click", function () {
				objCommons.disableButton(this.id);
				location.href = "../commander";
			});

			//rend le tableau triable par colonne
			objCommons.sortTable(document.getElementById("tableau_licence"));

			//renvoie sur la page de transfert
			if (document.getElementById("bouton_nouveau_transfert")) {
				document.getElementById("bouton_nouveau_transfert").addEventListener("click", function () {
					objCommons.disableButton(this.id);
					location.href = "../transfert";
				});
			}

			//renvoie sur la page de transfert
			if (document.getElementById("bouton_utiliser_transfert")) {
				document.getElementById("bouton_utiliser_transfert").addEventListener("click", function () {
					objTransferts.dialogUtiliserTransfert();
				});
			}

			//boucle pour chaque formulaire de la page
			document.getElementById("tableau_transferts").querySelectorAll("tbody tr").forEach(function (tr) {
				var _k = tr.id.split("_")[1];

				//email du transfert
				if (document.getElementById("email_transfert_" + _k)) {
					document.getElementById("email_transfert_" + _k).addEventListener("click", function () {
						objTransferts.dialogEmailTransfert(_k);
					});
				}

				//suppression du transfert
				if (document.getElementById("delete_transfert_" + _k)) {
					document.getElementById("delete_transfert_" + _k).addEventListener("click", function () {
						objTransferts.dialogDeleteTransfert(_k);
					});
				}
			});
		});
	}
	/**
	 * Page de commande de licences
	 */
	licenceModifier() {
		this.licenceCommander();
	}
	/**
	 * Page de transfert de licences
	 */
	licenceTransfert() {

		//initialise le menu
		objMenu.licence();

		//importe les modules
		import('./transferts').then((Transferts) => {

			//creer un nouvel objet
			window.objTransferts = new Transferts.default();

			//génère le select communes
			objCommons.getCommunes(true);

			//action sur le code uai
			document.getElementById("code_uai").addEventListener("keyup", function (e) {

				//ignore le shift
				if (e.keyCode != 16) {
					objCommons.getEtablissement();
				}
			});

			//validation du formulaire de nouveau transfert
			document.getElementById("valide_nouveau_transfert").addEventListener("click", function () {
				if (document.getElementById("nb_licences_transfert").checkValidity()) {

					//si un établissement est sélectionné
					if (document.getElementById("code_uai").value != "") {
						objTransferts.dialogConfirmTransfert();
					} else {
						objTransferts.nouveauTransfert();
					}
				} else {
					objCommons.isInvalid("nb_licences_transfert");
				}
			});

			//annulation du formulaire de nouveau transfert
			document.getElementById("cancel_nouveau_transfert").addEventListener("click", function () {
				objCommons.disableButton(this.id);
				location.href = "gerer";
			});
		});
	}
	/**
	 * Page avec le listing des patients
	 */
	orthophonistePatients() {

		//importe les modules
		import('./patients').then((Patients) => {

			//créer un nouvel objet
			window.objPatients = new Patients.default();

			//action sur le bouton_add_ligne_patient
			document.getElementById("bouton_create_patient").addEventListener("click", function () {
				objPatients.openDialog(null);
			});

			//affiche que les connectés
			document.getElementById("case_afficher_tout").addEventListener("click", function () {
				var icon = this.querySelector("div");

				//Toggle the button state
				this.classList.toggle("active");

				// Toggle the icon based on the button state
				if (this.classList.contains("active")) {
					icon.classList.remove("icone-show-all");
					icon.classList.add("icone-show-all-vert");
					let _nb_patients = 0;

					//affiche les patients
					document.querySelectorAll(".form_patient").forEach(function (element) {
						if (element.classList.contains("patient_supprime")) {
							if (document.getElementById("case_comptes_supprimes").classList.contains("active")) {
								element.style.display = "table-row";
								_nb_patients++;
							} else {
								element.style.display = "none";
							}
						} else {
							element.style.display = "table-row";
							_nb_patients++;
						}
					});

					//si des patients ont été affichés
					if (_nb_patients > 0) {
						document.getElementById("search_patient").style.display = "none";
					}
				} else {
					icon.classList.remove("icone-show-all-vert");
					icon.classList.add("icone-show-all");

					//cache les patients
					document.querySelectorAll(".form_patient").forEach(function (element) {
						element.style.display = "none";
					});
				}
			});

			//affiche que les connectés
			document.getElementById("case_comptes_supprimes").addEventListener("click", function () {
				if (document.getElementById("search_patient").style.display == "none") {
					var icon = this.querySelector("div");

					//Toggle the button state
					this.classList.toggle("active");

					// Toggle the icon based on the button state
					if (this.classList.contains("active")) {
						icon.classList.remove("icone-trash");
						icon.classList.add("icone-trash-vert");

						//affiche les patients
						document.querySelectorAll(".form_patient").forEach(function (element) {
							if (element.classList.contains("patient_supprime")) {
								if (document.getElementById("case_comptes_supprimes").classList.contains("active")) {
									element.style.display = "table-row";
								} else {
									element.style.display = "none";
								}
							}
						});
					} else {
						icon.classList.remove("icone-trash-vert");
						icon.classList.add("icone-trash");

						//cache les patients
						document.querySelectorAll(".form_patient").forEach(function (element) {
							if (element.classList.contains("patient_supprime")) {
								element.style.display = "none";
							}
						});
					}
				}
			});

			//champ de recherche des patients
			if (document.getElementById("mots")) {
				document.getElementById("mots").addEventListener("keyup", function () {
					objPatients.search();
				});
			}

			//rend le tableau triable par colonne
			objCommons.sortTable(document.getElementById("tableau_patients"));

			//boucle pour chaque formulaire de la page
			document.querySelectorAll("#tableau_patients tbody tr").forEach(function (tr) {
				var _k = tr.id.split("_")[1];

				//actions sur les fiches
				if (document.getElementById("dossier_" + _k)) {
					document.getElementById("dossier_" + _k).addEventListener("click", function () {
						location.href = "../patients/dossier/" + _k;
					});
				}

				//actions sur les icones modifier
				if (document.getElementById("modifier_" + _k)) {
					document.getElementById("modifier_" + _k).addEventListener("click", function () {
						objPatients.openDialog(_k);
					});
				}

				//actions sur les icones supprimer
				if (document.getElementById("supprimer_" + _k)) {
					document.getElementById("supprimer_" + _k).addEventListener("click", function () {
						objPatients.dialogDelete(_k);
					});
				}

				//actions sur les icones reactriver
				if (document.getElementById("reactiver_" + _k)) {
					document.getElementById("reactiver_" + _k).addEventListener("click", function () {
						objPatients.reactiver(_k);
					});
				}
			});
		});
	}
	/**
	 * Page de details d'une séance
	 */
	patientsDetailSeance() {

		//initialise le menu
		objMenu.orthophoniste();

		//importe les modules
		import('./patients').then((Patients) => {

			//créer un nouvel objet
			window.objPatients = new Patients.default();
		});
	}
	/**
	 * Page de séance à domicile pour un patient
	 */
	patientsDomicile() {
		javascript_page_vars.id_last_passation = null;

		//initialise le menu
		objMenu.orthophoniste();

		//importe les modules
		Promise.all([
			import('./patients'),
			import('./lirecouleur')
		]).then(([Patients, Lirecouleur]) => {

			//créer un nouvel objet
			window.objPatients = new Patients.default();
			window.objLireCouleur = new Lirecouleur.default();

			//action sur le bouton de nouvelle
			if (document.getElementById("nouvelle_seance_domicile")) {
				document.getElementById("nouvelle_seance_domicile").addEventListener("click", function () {
					objCommons.disableButton(this.id);
					objPatients.checkDomicile();
				});
			}

			//actions toggleButtons
			objCommons.toggleButton("mots_bleus");
			objCommons.toggleButton("question_preparatoire");
			objCommons.toggleButton("lecture_audio");
			objCommons.toggleButton("score");
			objCommons.toggleButton("avancement");

			//action sur le bouton de validation
			if (document.getElementById("valider_seance_domicile")) {
				document.getElementById("valider_seance_domicile").addEventListener("click", function () {
					objCommons.disableButton(this.id);
					objPatients.createDomicile();
				});
			}

			//action sur le bouton annulation
			if (document.getElementById("annuler_seance_domicile")) {
				document.getElementById("annuler_seance_domicile").addEventListener("click", function () {
					document.getElementById("seance_en_cours").style.display = "block";
					document.getElementById("nouvelle_seance").style.display = "none";
				});
			}

			//action sur le lien guide connexion
			if (document.getElementById("lien_guide_connexion")) {
				document.getElementById("lien_guide_connexion").addEventListener("click", function () {
					window.open("../get_guide_patient/" + javascript_page_vars.id_patient);
				});
			}

			//action sur le bouton commenter item
			if (document.getElementById("commenter_item")) {
				document.getElementById("commenter_item").addEventListener("click", function () {

					//importe les modules
					import('./item').then((Item) => {

						//créer un nouvel objet
						window.objItem = new Item.default();
						objItem.dialogCommentaire();
					});
				});

				//action sur le bouton fermer item
				document.getElementById("fermer_show_item").addEventListener("click", function () {
					document.getElementById("show_item").style.display = "none";
					document.querySelectorAll(".case_item").forEach(function (case_item) {
						case_item.classList.remove("case_item_selected");
					});
				});

				//action sur le bouton replace_item
				document.getElementById("replace_item").addEventListener("click", function () {
					objPatients.replaceItem();
				});

				//modifie le mode de vue
				document.getElementById("vue_neutre").addEventListener("change", function () {
					document.getElementById("div_contenu_item").style = "";
					javascript_page_vars.id_last_reponse = 0;
					objPatients.refreshReponsesItem();
				});

				//modifie le mode de vue
				document.getElementById("vue_patient").addEventListener("change", function () {
					javascript_page_vars.id_last_reponse = 0;
					objPatients.refreshReponsesItem();
				});
			}

			//rafraichis les cases de couleurs
			objPatients.refreshTableauItems();

			//subscribe au channel
			objRatchet.sendCommand({
				command: "subscribe",
				channel: "patient",
				id_patient: javascript_page_vars.id_patient
			});
		});
	}
	/**
	 * Page de dossier d'un patient
	 */
	patientsDossier() {

		//initialise le menu
		objMenu.orthophoniste();

		//importe les modules
		Promise.all([
			import('./patients'),
			import('./resultats')
		]).then(([Patients, Resultats]) => {

			//créer un nouvel objet
			window.objPatients = new Patients.default();
			window.objResultats = new Resultats.default();

			//action sur le bouton exercices
			document.getElementById("bouton_exercices").addEventListener("click", function () {
				objCommons.disableButton(this.id);
				location.href = "../exercices/" + javascript_page_vars.id_patient;
			});

			//action sur le bouton exercices
			document.getElementById("bouton_seance_domicile").addEventListener("click", function () {
				objCommons.disableButton(this.id);
				location.href = "../domicile/" + javascript_page_vars.id_patient;
			});

			//action sur le bouton Télécharger
			if (document.getElementById("bouton_telecharger")) {
				document.getElementById("bouton_telecharger").addEventListener("click", function () {

					//met en attente
					objCommons.disableButton(this.id);

					//récupère le PDF
					objResultats.getPdf("patient");
				});
			}

			//action sur le bouton notes
			document.getElementById("bouton_notes").addEventListener("click", function () {
				objPatients.dialogNotes();
			});

			//action sur le afficher seances
			if (document.getElementById("afficher_seances")) {
				document.getElementById("afficher_seances").addEventListener("change", function () {
					const elements = document.querySelectorAll("#liste_seances_patient > div");
					elements.forEach(function (element) {

						//si c'est que les 8 dernières
						if (document.getElementById("afficher_seances").value == "dernieres_seances") {
							var num_seance = element.id.split("_")[1];
							if (num_seance < elements.length - 7) {
								element.style.display = "none";
							}
						} else {
							element.style.display = "block";
						}
					});
				});
			}

			//action sur le menu reperes
			document.getElementById("reperes_graphiques").addEventListener("click", function () {
				objResultats.showRepere(this.value);
				objPatients.updateNotes();
			});

			//affiche les notes du patients
			objPatients.updateNotes();

			//scroll en bas de la div
			var objDiv = document.getElementById("liste_seances_patient");
			objDiv.scrollTop = objDiv.scrollHeight;
		});
	}
	/**
	 * Page d'exercice d'un patient
	 */
	patientsExercices() {

		//initialise le menu
		objMenu.orthophoniste();

		//importe les modules
		Promise.all([
			import('./patients'),
			import('./lirecouleur')
		]).then(([Patients, Lirecouleur]) => {

			//créer un nouvel objet
			window.objPatients = new Patients.default();
			window.objLireCouleur = new Lirecouleur.default();

			//action sur les bouton afficher/cacher
			document.querySelectorAll(".div_oeil").forEach(function (element) {

				//au click
				element.addEventListener("click", function () {

					//affiche l'élément
					var id = this.id.replace("show_", "");
					var contenu = document.getElementById(id);
					contenu.style.display = contenu.style.display === "none" ? "block" : "none";

					//modifie l'icone oeil
					if (element.classList.contains('icone-oeil-close')) {
						element.classList.remove('icone-oeil-close');
						element.classList.add('icone-oeil');
					} else {
						element.classList.remove('icone-oeil');
						element.classList.add('icone-oeil-close');
					}
				});
			});

			//action sur le bouton last_item
			if (document.getElementById("bouton_last_item")) {
				document.getElementById("bouton_last_item").addEventListener("click", function () {
					document.getElementById("item_en_cours").style.display = "none";
					document.getElementById("boutons_item_en_cours").style.display = "none";
					document.getElementById("item_precedent").style.display = "block";
					document.getElementById("boutons_item_precedent").style.display = "block";
				});

				//action sur le bouton retour
				document.getElementById("bouton_retour").addEventListener("click", function () {
					document.getElementById("item_en_cours").style.display = "block";
					document.getElementById("boutons_item_en_cours").style.display = "block";
					document.getElementById("item_precedent").style.display = "none";
					document.getElementById("boutons_item_precedent").style.display = "none";
				});
			}

			//action sur le bouton new_item
			if (document.getElementById("bouton_new_item")) {
				document.getElementById("bouton_new_item").addEventListener("click", function () {
					objPatients.passerExercice();
				});
			}

			//action sur le bouton get_clone
			if (document.getElementById("bouton_get_clone")) {
				document.getElementById("bouton_get_clone").addEventListener("click", function () {
					objPatients.getClone();
				});
			}

			//action sur les bouton_valider_reponse
			if (document.getElementById("div_bouton_valider_reponse")) {
				document.getElementById("bouton_valider_reponse_seul").addEventListener("click", function () {
					objPatients.repExercice(false);
				});
				document.getElementById("bouton_valider_reponse_aide").addEventListener("click", function () {
					objPatients.repExercice(true);
				});
			}

			//action sur le button_next
			if (document.getElementById("button_next")) {
				document.getElementById("button_next").addEventListener("click", function () {
					objCommons.disableButton(this.id);
					location.href = javascript_page_vars.id_patient;
				});
			}

			//action sur le bouton commenter item
			if (document.getElementById("bouton_commenter_item")) {
				document.getElementById("bouton_commenter_item").addEventListener("click", function () {

					//importe les modules
					import('./item').then((Item) => {

						//créer un nouvel objet
						window.objItem = new Item.default();
						objItem.dialogCommentaire();
					});
				});
			}

			//initialise les parametres avec les valeurs enregistrées
			objLireCouleur.initParametresAffichage();

			//lance la fonction lireCouleur
			objLireCouleur.dysTexte(javascript_page_vars.affichage.lire_couleur, true);

			//changement du mode
			document.getElementById("mode").addEventListener("change", function () {
				objPatients.changeNiveauItems();
			});

			//action sur le bouton rejouer
			document.getElementById("rejouer").addEventListener("change", function () {
				objPatients.changeIndices("rejouer", this.value);
			});

			//bouton score
			objCommons.toggleButton("score");
			document.getElementById("score").addEventListener("click", function () {
				objPatients.changeIndices("score", document.querySelector("#" + this.id + " span").classList.contains("valideButton"));
			});

			//action sur le bouton timer
			document.getElementById("create_timer").addEventListener("click", function () {
				objPatients.createTimer();
			});

			//action sur le bouton timer
			if (document.getElementById("timer")) {
				document.getElementById("timer").addEventListener("click", function () {
					objPatients.createTimer();
				});
			}

			//si le timer était déjà commencé
			if (sessionStorage.getItem("timer_radius_" + javascript_page_vars.id_patient)) {
				if (parseInt(sessionStorage.getItem("timer_radius_" + javascript_page_vars.id_patient)) != 0) {
					objPatients.animateTimer();
				}
			}

			//action sur le bouton quitter
			document.getElementById("bouton_quitter").addEventListener("click", function () {
				objCommons.disableButton(this.id);
				location.href = "../dossier/" + javascript_page_vars.id_patient;
			});

			//changement du niveau_items
			document.getElementById("niveau_items").addEventListener("change", function () {
				objPatients.changeNiveauItems();
			});

			//changement du word_count
			document.getElementById("word_count").value = javascript_page_vars.affichage.word_count;
			document.getElementById("word_count").addEventListener("change", function () {
				objPatients.changeWordCount(this.value);
			});

			//action sur le bouton types
			document.getElementById("types").addEventListener("click", function () {
				objDialog.setId("dialog_types");
				objDialog.show();
			});

			//action sur le bouton valider types d'exercices
			document.getElementById("dialog_types_valider").addEventListener("click", function () {
				objCommons.disableButton(this.id);
				objPatients.saveTypes();
			});

			//action sur le bouton valider types d'exercices
			document.getElementById("dialog_types_annuler").addEventListener("click", function () {
				objDialog.hide();
			});

			//bouton mots_bleus
			objCommons.toggleButton("mots_bleus");
			document.getElementById("mots_bleus").addEventListener("click", function () {
				objPatients.changeIndices("mots_bleus", document.querySelector("#" + this.id + " span").classList.contains("valideButton"));
			});

			//bouton mots_bleus
			objCommons.toggleButton("add_question_preparatoire");
			document.getElementById("add_question_preparatoire").addEventListener("click", function () {
				objPatients.changeIndices("question_preparatoire", document.querySelector("#" + this.id + " span").classList.contains("valideButton"));
			});

			//bouton affichage_sequentiel
			objCommons.toggleButton("affichage_sequentiel");
			document.getElementById("affichage_sequentiel").addEventListener("click", function () {
				objPatients.changeIndices("affichage_sequentiel", document.querySelector("#" + this.id + " span").classList.contains("valideButton"));
			});

			//cache la div de loading
			if (document.getElementById("div_contenu_item_loading")) {
				document.getElementById("div_contenu_item_loading").style.display = "none";
			}

			//scroll jusqu'au menu
			document.getElementById("menu").scrollIntoView();
		});
	}
	/**
	 * Page de séance à domicile
	 */
	patientSeance() {

		//importe les modules
		Promise.all([
			import('./patients'),
			import('./lirecouleur')
		]).then(([Patients, Lirecouleur]) => {

			//créer un nouvel objet
			window.objPatients = new Patients.default();
			window.objLireCouleur = new Lirecouleur.default();

			//initialise les parametres avec les valeurs enregistrées
			objLireCouleur.initParametresAffichage();

			//lance la fonction lireCouleur
			objLireCouleur.dysTexte(javascript_page_vars.affichage.lire_couleur, false);

			//vérifie la réponse de l'élève
			if (document.getElementById("bouton_valider_reponse_seul")) {
				document.getElementById("bouton_valider_reponse_seul").addEventListener("click", function () {
					objPatients.repExercice(false);
				});
			}

			//passe à la question suivante
			if (document.getElementById("bouton_question_suivante")) {
				document.getElementById("bouton_question_suivante").addEventListener("click", function () {
					objCommons.disableButton(this.id);
					location.reload();
				});
			}

			//regarde s'il y a une consigne
			objPatients.dialogConsigne(javascript_page_vars.consigne);

			//cache la div de loading
			if (document.getElementById("div_contenu_item_loading")) {
				document.getElementById("div_contenu_item_loading").style.display = "none";
			}

			//empeche le copié collé
			objCommons.disableSelection();
		});
	}
	/**
	 * Page d'accueil
	 */
	presentationAccueil() {

		//bouton presentation enseignant
		document.getElementById("diaporama_enseignant").addEventListener("click", function () {
			objCommons.saveCookie("enseignant", "documents/diaporama/enseignant");
		});

		//bouton manuel enseignant
		document.getElementById("manuel_enseignant").addEventListener("click", function () {
			objCommons.saveCookie("enseignant", "documents/manuel/enseignant");
		});

		//bouton tester enseignant
		document.getElementById("tester_enseignant").addEventListener("click", function () {
			objCommons.saveCookie("enseignant", "inscription/test");
		});

		//bouton presentation orthophoniste
		document.getElementById("diaporama_orthophoniste").addEventListener("click", function () {
			objCommons.saveCookie("orthophoniste", "documents/diaporama/orthophoniste");
		});

		//bouton manuel orthophoniste
		document.getElementById("manuel_orthophoniste").addEventListener("click", function () {
			objCommons.saveCookie("orthophoniste", "documents/manuel/orthophoniste");
		});

		//bouton tester orthophoniste
		document.getElementById("tester_orthophoniste").addEventListener("click", function () {
			objCommons.saveCookie("orthophoniste", "inscription/orthophoniste");
		});

		//bouton acces enseignant
		document.getElementById("acces_enseignant").addEventListener("click", function () {
			objCommons.saveCookie("enseignant", "connexion/utilisateur");
		});

		//bouton acces eleve
		document.getElementById("acces_eleve").addEventListener("click", function () {
			location.href = javascript_common_vars.url + "connexion/eleve";
		});

		//bouton acces ortho
		document.getElementById("acces_ortho").addEventListener("click", function () {
			objCommons.saveCookie("orthophoniste", "connexion/utilisateur");
		});

		//bouton acces patient
		document.getElementById("acces_patient").addEventListener("click", function () {
			location.href = javascript_common_vars.url + "connexion/patient";
		});

		//bouton tarif
		document.getElementById("voir_tarifs").addEventListener("click", function () {
			location.href = javascript_common_vars.url + "presentation/tarif#tarifs";
		});

		//bouton devis
		document.getElementById("devis").addEventListener("click", function () {
			location.href = javascript_common_vars.url + "inscription/devis";
		});

		//bouton tarif
		document.getElementById("commande_publique").addEventListener("click", function () {
			location.href = javascript_common_vars.url + "inscription/organisme_public";
		});

		//bouton vidéos
		document.getElementById("video_presentation").addEventListener("click", function () {
			window.open(javascript_common_vars.url + "documents/manuel/enseignant#8.1", "_blank");
		});
	}
	/**
	 * Page avec la carte de répartition
	 */
	presentationCarte() {

		//importe les modules
		import('./carte').then((Carte) => {

			//créer un nouvel objet
			window.objCarte = new Carte.default();

			//recupere la carte
			objCarte.create();
		});
	}
	/**
	 * Page avec la justif du tarif
	 */
	presentationTarif() {
		if (location.hash) {
			let target = location.hash;
			window.scrollTop = document.querySelector(target).offsetTop;
		}
	}
	/**
	 * Page avec les résultats d'un groupe
	 */
	resultatsVoir() {

		//initialise le menu
		if (javascript_common_vars.statut == "etablissement" || javascript_common_vars.statut == "administrateur") {
			objMenu.classes();
		} else {
			objMenu.groupes();
		}

		//importe les modules
		Promise.all([
			import('./resultats'),
			import('./tchat')
		]).then(([Resultats, Tchat]) => {

			//créer un nouvel objet
			window.objResultats = new Resultats.default();
			window.objTchat = new Tchat.default();

			//initialise les ongles
			objResultats.initOnglets();

			//action sur le menu reperes
			document.getElementById("reperes_graphiques").addEventListener("change", function () {
				objResultats.showRepere(this.value);

				//si c'est sur les résultats d'un élève
				if (document.getElementById("resultats_detailles").classList.contains("onglet_selected") && document.getElementById("repere_eleve_" + document.getElementById("liste_eleves").value).value != this.value) {
					document.getElementById("repere_eleve_" + document.getElementById("liste_eleves").value).value = this.value;

					//sauvegarde le repère pour l'élève
					$.ajax({
						type: "POST",
						url: javascript_common_vars.url + "resultats/save_repere",
						data: {
							"id_eleve": document.getElementById("liste_eleves").value,
							"repere": this.value
						}
					});
				}
			});

			//action sur le tri des résultats
			document.getElementById("tri_resultats").addEventListener("change", function () {
				objResultats.trier();
			});

			//action sur le bouton Télécharger
			if (document.getElementById("bouton_telecharger_groupe")) {
				document.getElementById("bouton_telecharger_groupe").addEventListener("click", function () {
					objCommons.disableButton(this.id);

					//récupère le PDF
					objResultats.getPdf("groupe");
				});
			}

			//action sur la liste d'élèves
			document.getElementById("liste_eleves").addEventListener("change", function () {

				//cache les résultats de l'élève actuel
				document.querySelectorAll(".resultats_eleve").forEach(function (element) {
					element.style.display = "none";
				});

				//si l'élève a des résultats
				if (document.getElementById("tableau_resultats_eleve_" + this.value)) {

					//place le repère dans le graphique de l'élève sélectionné
					var parent = document.getElementById("tableau_resultats_eleve_" + this.value);
					parent.insertBefore(document.getElementById("repere"), parent.firstChild);

					//modifie le repère sélectionné
					document.getElementById("reperes_graphiques").value = document.getElementById("repere_eleve_" + document.getElementById("liste_eleves").value).value;

					//simule l'évènement onchange
					var event;
					if (typeof (Event) === "function") {
						event = new Event("change");
					} else {
						event = document.createEvent("Event");
						event.initEvent("change", true, true);
					}
					document.getElementById("reperes_graphiques").dispatchEvent(event);

					//ajoute les batons d'évaluations
					document.getElementById("batons_evals").innerHTML = document.getElementById("resultats_eleve_" + document.getElementById("liste_eleves").value + "_evals").innerHTML;
				}

				//affiche les résultats
				document.getElementById("resultats_eleve_" + this.value).style.display = "block";
			});

			//action sur le bouton télécharger
			if (document.getElementById("bouton_telecharger_eleves")) {
				document.getElementById("bouton_telecharger_eleves").addEventListener("click", function () {
					objResultats.dialogTelechargement();
				});
			}

			//initialise le menu
			if (javascript_common_vars.statut != "etablissement" && javascript_common_vars.statut != "administrateur") {

				//initialise le chat
				objTchat.init();
		}
		});
	}
	/**
	 * Page avec les statistiques de la bdd
	 */
	statistiquesVoir() {

		//importe les modules
		import('./graphiques').then((Graphiques) => {

			//créer un nouvel objet
			window.objGraphiques = new Graphiques.default();

			//change le module
			document.getElementById("module_selectionne").addEventListener("change", function () {
				location.href = this.value;
			});

			//lance les fonctions de recuperation des donnees
			objGraphiques.connexions();
			setTimeout(function () {
				objGraphiques.passations();
			}, 500);
			setTimeout(function () {
				objGraphiques.evaluations();
			}, 1000);
			setTimeout(function () {
				objGraphiques.scores();
			}, 1500);
		});
	}
	/**
	 * Page d'accueil des utilisateurs
	 */
	utilisateurAccueil() {

		//importe les modules
		import('./rubriques').then((Rubriques) => {

			//genere la grille de rubriques
			window.objRubriques = new Rubriques.default();

			//lien pour passer en mode enseignant
			if (document.getElementById("switch_mode")) {
				document.getElementById("switch_mode").addEventListener("click", function (e) {

					//envoie la requete au serveur pour changer de mode
					$.ajax({
						type: "GET",
						url: javascript_common_vars.url + "utilisateur/switch_mode",
						cache: false
					}).done(function () {
						location.reload();
					});
				});
			}

			//initialise le dnd
			objRubriques.initDnd();

			//actions sur les rubriques
			document.querySelectorAll(".colonne_rubriques .div_bottom_container").forEach(function (rubrique) {

				//click sur la div
				rubrique.addEventListener("click", function () {
					if (document.getElementById(rubrique.id + "_lien").value != "") {
						location.href = document.getElementById(rubrique.id + "_lien").value;
					}
				});

				//s'il y a un bouton refresh
				if (document.getElementById(rubrique.id + "_refresh")) {
					document.getElementById(rubrique.id + "_refresh").addEventListener("click", function (e) {
						var id = rubrique.id.split("_");
						e.stopPropagation();
						objRubriques.refreshContent(id[1]);
					});
				}

				//si c'est le rubrique forum
				if (rubrique.id == "rubrique_forum") {

					//boucle pour chaque sujet
					document.querySelectorAll(".sujet_rubrique_forum").forEach(function (sujet) {

						//action sur le click d'une colonne
						sujet.addEventListener("click", function (e) {
							e.stopPropagation();
							location.href = "../forum/sujet/" + sujet.getAttribute("name").split("_")[1];
						});
					});
				}

				//si c'est le rubrique items
				if (rubrique.id == "rubrique_items") {

					//boucle pour chaque auteurs
					document.querySelectorAll(".auteurs_rubrique_items").forEach(function (auteur) {

						//action sur le click d'une colonne
						auteur.addEventListener("click", function (e) {
							e.stopPropagation();
							objCommons.showAuteurs(auteur.id.split("_")[1]);
						});
					});
				}
			});

			//affichage des actu et des sujets tout les 10 secondes
			setInterval("objRubriques.switchDiv('sujets')", 5000);
		});
	}
	/**
	 * Page avec les paramètres utilisateurs
	 */
	utilisateurParametres() {

		//importe les modules
		import('./user').then((User) => {

			//créer un nouvel objet
			window.objUser = new User.default();

			//valide la mise à jour des infos
			document.getElementById("valider").addEventListener("click", function () {
				objUser.updateInfos();
			});

			//valide la mise à jour des infos
			if (document.getElementById("supprimer")) {
				document.getElementById("supprimer").addEventListener("click", function () {
					objUser.dialogDelete(javascript_page_vars.id_user);
				});
			}

			//valide la mise à jour du pass
			document.getElementById("valider2").addEventListener("click", function () {
				objUser.updatePass();
			});

			//estime la securite du mot de passe
			document.getElementById("pass").addEventListener("keyup", function () {
				objUser.evalPass();
			});
		});
	}
	/**
	 * Page de test
	 */
	utilisateurTest() {
		function createEmojiElement(emoji) {
			const emojiElement = document.createElement('div');
			emojiElement.innerHTML = "&#" + emoji + ";";
			return emojiElement;
		}

		document.getElementById('fireworkButton').addEventListener('click', function (e) {
			const emojis = [
				9733,
				10033,
				10040,
				10022,
				11088,
				128525,
				128526,
				129321,
				129395,
				128077,
				128170,
				127808,
				127942,
				127941,
				128079
			]; // Liste des émojis
			const selectedEmoji = emojis[Math.floor(Math.random() * emojis.length)]; // Sélectionne un émoji au hasard

			//nombre de symbole
			const shapeCount = 5 + Math.random() * 10;
			for (let i = 0; i < shapeCount; i++) {
				const shape = createEmojiElement(selectedEmoji);
				shape.style.position = 'absolute';
				shape.style.zIndex = '99999';
				shape.style.fontSize = '30px';
				shape.style.color = "green";

				const angle = Math.random() * Math.PI * 2;
				const radius = 30 + Math.random() * 30;
				const offsetX = Math.cos(angle) * radius;
				const offsetY = Math.sin(angle) * radius;

				const buttonRect = e.target.getBoundingClientRect();
				const buttonCenterX = buttonRect.left + buttonRect.width / 2 + window.scrollX;
				const buttonCenterY = buttonRect.top + buttonRect.height / 2 + window.scrollY;

				shape.style.left = (buttonCenterX + offsetX) + 'px';
				shape.style.top = (buttonCenterY + offsetY) + 'px';

				// Animation pour simuler un mouvement d'explosion
				const animationDistance = 10; // Augmentez pour un mouvement plus grand
				const finalX = Math.cos(angle) * animationDistance + offsetX;
				const finalY = Math.sin(angle) * animationDistance + offsetY;

				shape.animate([
					// Début : Position initiale et pleine opacité
					{transform: `translate(0, 0) scale(1)`, opacity: 0.6 + Math.random() * (1 - 0.6)},
					// Fin : Position finale, réduite et estompée
					{transform: `translate(${finalX}px, ${finalY}px) scale(0)`, opacity: 0}
				], {
					duration: 2500, // Durée de l'animation en millisecondes
					easing: 'ease-out'
				}).onfinish = () => {
					shape.remove(); // Supprime l'étoile à la fin de l'animation
				};

				document.body.appendChild(shape);
			}
		});
	}
}