import selectize from 'selectize';

// Création de la classe commons
export default class Commons {
	/*
	 * Vérifie la version du navigateur
	 */
	checkNavigator() {

		//si les cookies sont pas autorisés
		if (!navigator.cookieEnabled) {

			//id du dialog
			const id_dialog = "dialog_navigateur";
			objDialog.setId(id_dialog);

			//si le dialog n'existe pas
			if (!document.getElementById(id_dialog)) {

				//création du dialog
				objDialog.create("Activation des cookies requise", false);

				//message d'alerte
				const span = document.createElement("span");
				let html = "<b>Afin de profiter pleinement de notre plateforme, vous devez activer les cookies sur votre navigateur.</b><br>";
				html = html + "<br>Si vous rencontrez des difficultés, vous pouvez nous contacter à l'adresse suivante : <a href='mailto:contact-tacit@univ-rennes2.fr'>contact-tacit@univ-rennes2.fr</a>.";
				span.innerHTML = html;
				objDialog.updateContent(span);
			}

			//cache la croix
			objDialog.hideClose();

			//affiche la background opaque
			document.querySelectorAll('.dialog').forEach(function (element) {
				element.style.backgroundColor = "#e5e5e5";
			});

			//ouvre le dialog
			objDialog.show();
		} else {
			const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
			const isIE = navigator.userAgent.toLowerCase().indexOf('trident') > -1;
			if ((isFirefox && objCommons.getFirefoxVersion() < 10) || (isIE && objCommons.getIEVersion() < 9)) {

				//id du dialog
				const id_dialog = "dialog_navigateur";
				objDialog.setId(id_dialog);

				//si le dialog n'existe pas
				if (!document.getElementById(id_dialog)) {

					//création du dialog
					objDialog.create("Mise à jour du navigateur requise", false);

					//message d'alerte
					const span = document.createElement("span");
					let html = "<b>Afin de profiter pleinement de notre plateforme, vous devez mettre à jour votre navigateur.</b><br><br>";
					if (isFirefox) {
						html = html + "<div><a href='http://www.mozilla.org/fr/firefox/new/' target='_blank'>Mozilla Firefox (version 10 minimum)</a></div>";
					} else {
						html = html + "<div><a href='https://support.microsoft.com/en-us/help/17621' target='_blank'>Internet Explorer (version 9 minimum)</a></div>";
					}
					html = html + "<br>Si vous rencontrez des difficultés, vous pouvez nous contacter à l'adresse suivante : <a href='mailto:contact-tacit@univ-rennes2.fr'>contact-tacit@univ-rennes2.fr</a>.";
					span.innerHTML = html;
					objDialog.updateContent(span);
				}

				//cache la croix
				objDialog.hideClose();

				//affiche la background opaque
				document.querySelectorAll('.dialog').forEach(function (element) {
					element.style.backgroundColor = "#e5e5e5";
				});

				//ouvre le dialog
				objDialog.show();
			}
		}
	}
	/*
	 * regarde si le meme compte a été connecté ailleurs
	 */
	checkAilleurs() {
		$.getJSON(javascript_common_vars.url + "logout/check_ailleurs", function (data) {
			if (data.message == "true") {
				window.location.href = javascript_common_vars.url + "connexion/ailleurs";
			}
		});
	}
	/*
	 * Récupère la version de Firefox
	 */
	getFirefoxVersion() {
		const matches = navigator.userAgent.match(/Firefox\/([0-9]+)/);
		if (matches) {
			return parseInt(matches[1]);
		}
		return 0;
	}
	/*
	 * Récupère la version d'IE
	 */
	getIEVersion() {
		const matches = navigator.userAgent.match(/MSIE ([0-9]+)/);
		if (matches) {
			return parseInt(matches[1]);
		}
		return 0;
	}
	/*
	 * Création d'un tooltip
	 */
	createTooltip(element) {

		//création du tooltip
		const tooltip = document.createElement("div");
		tooltip.classList.add("tooltip");
		tooltip.innerHTML = element.getAttribute("tooltip-content");

		//création de la flèche
		const fleche = document.createElement("div");
		fleche.classList.add("tooltip-fleche");

		//fonction pour rafraîchir le contenu du tooltip
		element.refreshTooltipContent = function (newContent) {
			tooltip.innerHTML = newContent;
		};

		//affichage au mouseenter
		element.addEventListener('mouseenter', function () {

			//position de l'élément
			const viewportWidth = document.documentElement.clientWidth;
			const elementRect = this.getBoundingClientRect();
			const scrollY = window.scrollY || window.pageYOffset;

			//ajoute le tooltip au dom
			document.body.appendChild(tooltip);
			document.body.appendChild(fleche);

			//hauteur du tooltip
			const tooltip_top = elementRect.top + scrollY - tooltip.offsetHeight - 12;
			tooltip.style.top = tooltip_top + 'px';
			fleche.style.top = tooltip_top + tooltip.offsetHeight + 'px';

			//left du tooltip
			const tooltip_left = elementRect.left + elementRect.width / 2;
			tooltip.style.left = tooltip_left - tooltip.offsetWidth / 2 + "px";
			fleche.style.left = tooltip_left - 5 + "px";

			//left to integer
			const leftAsInteger = parseInt(tooltip.style.left, 10);

			//Vérifie si le tooltip dépasse les bords de la fenêtre
			if (leftAsInteger + tooltip.offsetWidth > viewportWidth) {
				tooltip.style.left = viewportWidth - tooltip.offsetWidth - 5 + 'px';
			} else if (leftAsInteger < 0) {
				tooltip.style.left = "5px";
			}
		});

		//cache au mouseleave
		element.addEventListener('mouseleave', function () {
			tooltip.remove();
			fleche.remove();
		});
	}
	/**
	 * Enregistre le cookie de choix
	 * 
	 * @param {string} value -> valeur du cookie
	 * @param {string} href -> redirection
	 */
	saveCookie(value, href) {
		document.cookie = "remember_me_choix=" + value;
		location.href = javascript_common_vars.url + href;
	}
	/**
	 * Récupère le contenu d'un cookie
	 * 
	 * @param {string} name -> valeur du cookie
	 */
	getCookie(name) {
		const value = `; ${document.cookie}`;
		const parts = value.split(`; ${name}=`);
		if (parts.length === 2) {
			return parts.pop().split(';').shift();
		} else {
			return "";
		}
	}
	/**
	 * Désactive un bouton js
	 * 
	 * @param {string} id -> id du bouton à désactiver
	 */
	disableButton(id) {
		const bouton = document.getElementById(id);
		const html = bouton.innerHTML.split("</span>");
		bouton.innerHTML = "<span class='waitButton'></span>" + html[1];
		bouton.disabled = true;
	}
	/**
	 * Active un bouton js
	 * 
	 * @param {string} id -> id du bouton à activer
	 * @param {string} iconClass -> class à appliquer au bouton
	 */
	enableButton(id, iconClass) {
		const bouton = document.getElementById(id);
		const html = bouton.innerHTML.split("</span>");
		bouton.innerHTML = "<span class='" + iconClass + "'></span>" + html[1];
		bouton.disabled = false;
	}
	/**
	 * Toggle l'icone valider sur un bouton
	 * 
	 * @param {string} id -> id du bouton
	 */
	toggleButton(id) {
		document.getElementById(id).addEventListener("click", function () {
			const span = document.querySelector("#" + id + " span");
			if (span.classList.contains("valideButton")) {
				span.classList.remove("valideButton");
			} else {
				span.classList.add("valideButton");
			}
		});
	}
	/**
	 * Affiche un message d'erreur dans un formulaire
	 * 
	 * @param {string} id -> id de la div d'erreur
	 * @param {string} html -> contenu du message
	 * @param {string} couleur -> couleur du message
	 */
	showFormError(id, html, couleur = "orange") {

		//élément concerné
		const element = document.getElementById(id);

		//supprime les couleurs s'il y a déjà
		if (element.classList.contains("orange") || element.classList.contains("bleu") || element.classList.contains("vert")) {
			element.classList.remove("orange", "bleu", "vert");
		}

		//icone en fonction de la couleur
		let icone = "";
		switch (couleur) {
			case "bleu":
				icone = "aides";
				break;
			case "orange":
				icone = "warning";
				break;
			case "vert":
				icone = "valide";
				break;
		}

		//affiche l'erreur dans la div
		element.innerHTML = "<div class='icone-" + icone + "'></div>&nbsp;&nbsp;" + html;
		element.classList.add(couleur);
		element.style.display = "block";
		element.style.opacity = 1;

		//cache au bout de 8 secondes
		objCommons.fadeOut(element);
	}
	/**
	 * Désactive la sélection de texte dans le document
	 */
	disableSelection() {

		//empeche le select
		document.getElementById("container").classList.add("disable_select");

		//empeche le ctrl + a
		document.addEventListener('keydown', function (objEvent) {
			if (objEvent.ctrlKey) {
				if (objEvent.keyCode == 65) {
					return false;
				}
			}
		});

		//empeche la selection
		if (typeof document.onselectstart != "undefined") {
			document.onselectstart = function () {
				return false;
			};
		} else {
			document.onmousedown = function () {
				return false;
			};
		}
	}
	/**
	 * Fonction qui ne coche qu'une seule case
	 * 
	 * @param {object} elem -> element sélectionné
	 */
	check(elem) {
		if (elem.checked) {
			document.querySelectorAll('[name="' + elem.name + '"]').forEach(function (element) {
				if (element.id != elem.id) {
					element.checked = false;
				}
			});
		}
	}
	/**
	 * Fonction qui récupère la date du jour
	 */
	todayDate() {
		const today = new Date();
		const yyyy = today.getFullYear();
		let mm = today.getMonth() + 1; // Months start at 0!
		let dd = today.getDate();

		if (dd < 10) {
			dd = '0' + dd;
		}
		if (mm < 10) {
			mm = '0' + mm;
		}

		return dd + '/' + mm + '/' + yyyy;
	}
	/**
	 * Simule un event
	 * 
	 * @param {object} elem -> element sélectionné
	 * @param {string} type -> type d'event
	 */
	triggerEvent(elem, type) {
		if (typeof Event === 'function') {
			var e = new Event(type, {
				bubbles: true,
				cancelable: false
			});
			elem.dispatchEvent(e);
		} else if (document.createEvent) {
			var e = document.createEvent('HTMLEvents');
			e.initEvent(type, true, false);
			elem.dispatchEvent(e);
		} else {
			// Handle the case where neither approach is supported
			console.error('Event dispatch not supported in this browser.');
		}
	}
	/**
	 * Regarde si un nombre est positif
	 * 
	 * @param {int} n -> le nombre a tester
	 */
	ispositive(n) {
		return 1 / (n * 0) === 1 / 0;
	}
	/**
	 * Regarde si un nombre est positif
	 * 
	 * @param {dom} element -> element qui doit fade
	 */
	fadeOut(element) {
		let duration = 500;
		let opacity = 1;
		let interval = 20;
		let steps = duration / interval;
		let delta = opacity / steps;
		setTimeout(function () {
			let fadeEffect = setInterval(function () {
				opacity -= delta;
				element.style.opacity = opacity;
				if (opacity <= 0) {
					clearInterval(fadeEffect);
					element.style.display = 'none';
				}
			}, interval);
		}, 8000);
	}
	/**
	 *  Affiche les champs invalides d'un formulaire
	 * 
	 * @param {int} id -> id du fomulaire
	 */
	highlightInvalid(id) {
		const invalidFields = document.getElementById(id).querySelectorAll(':invalid');
		invalidFields.forEach((field) => {
			objCommons.isInvalid(field.id);
		});
	}
	/**
	 * Rend un champ invalide
	 * 
	 * @param {int} id -> id de l'élément
	 */
	isInvalid(id) {
		var element = document.getElementById(id);
		if (id.indexOf("selectized") !== -1) {
			element = element.parentNode;
		}
		element.classList.add("input_invalid");
		setTimeout(function () {
			element.classList.remove("input_invalid");
		}, 3000);
	}
	/**
	 * Fixe la première ligne d'un tableau
	 * 
	 * @param {int} id -> id du tableau
	 */
	fixedThead(id) {
		const table = document.querySelector("#" + id);
		const firstRow = table.querySelector('thead th');

		table.addEventListener('scroll', () => {
			const tableRect = table.getBoundingClientRect();
			const firstRowRect = firstRow.getBoundingClientRect();
			const top = tableRect.top + firstRowRect.height;

			if (table.scrollTop >= top) {
				firstRow.style.top = `${table.scrollTop - top}px`;
			} else {
				firstRow.style.top = 0;
			}
		});
	}
	/**
	 * Décode une chaine HTML
	 * 
	 * @param {obj} input
	 */
	htmlDecode(input) {
		const doc = new DOMParser().parseFromString(input, "text/html");
		return doc.documentElement.textContent;
	}
	/**
	 * Trie un tableau
	 * 
	 * @param {element} table -> tableau
	 */
	sortTable(table) {
		const headers = table.querySelectorAll('th');

		//boucle pour chaque header
		headers.forEach((header, columnIndex) => {

			//double flèche par défaut
			if (header.dataset.sortable) {
				header.classList.add('sortable-default');

				//évènement au click
				header.addEventListener('click', () => {
					const tbody = table.querySelector('tbody');
					const rows = Array.from(tbody.children);
					const sortableType = header.dataset.sortable;

					//dans quel ordre?
					let sortOrder;
					if (table.dataset.sortBy == columnIndex && table.dataset.sortOrder === 'asc') {
						sortOrder = 'desc';
					} else {
						sortOrder = 'asc';
					}

					//trie les colonnes
					rows.sort((a, b) => {
						let cellA = a.children[columnIndex];
						let cellB = b.children[columnIndex];

						//si c'est le tableau des évaluations et la colonne avec le niveau
						if (table.classList.contains("table_evaluation_detail") && (columnIndex == 3 || columnIndex == 4)) {
							cellA = a.children[3].querySelector(".progression_texte");
							cellB = b.children[3].querySelector(".progression_texte");
						}

						//clean le contenu
						cellA = cellA.innerText.replace(/[\r\n]+/g, ' ').trim().replace(/ +(?= )/g, '');
						cellB = cellB.innerText.replace(/[\r\n]+/g, ' ').trim().replace(/ +(?= )/g, '');

						//tri en fonction du sortableType
						let comparison;
						if (sortableType === 'date') {
							const dateA = objCommons.parseCustomDate(cellA);
							const dateB = objCommons.parseCustomDate(cellB);
							comparison = dateA - dateB;
						} else if (sortableType === 'text' && /^\d/.test(cellA) && /^\d/.test(cellB)) {
							// Sort by number if both cells start with a number
							const numberA = parseFloat(cellA.replace(",", ".").replace(" ", "").match(/^\d+/)[0]);
							const numberB = parseFloat(cellB.replace(",", ".").replace(" ", "").match(/^\d+/)[0]);
							comparison = numberA - numberB;
						} else {
							// Handle default text column sorting
							comparison = cellA.localeCompare(cellB);
						}

						return sortOrder === 'asc' ? comparison : -comparison;
					});
					rows.forEach(row => tbody.appendChild(row));

					//met à jour les headers du tableau
					headers.forEach(header => {
						if (header.dataset.sortable) {
							header.classList.remove('asc', 'desc');
							header.classList.add('sortable-default');
						}
					});

					//met à jour la colonne cliquée
					header.classList.remove('sortable-default');
					header.classList.add(sortOrder);

					//enregistre les infos de tri
					table.dataset.sortBy = columnIndex;
					table.dataset.sortOrder = sortOrder;
				});
			}
		});
	}
	/**
	 * Parse une date passée
	 * 
	 * @param {string} dateString
	 */
	parseCustomDate(dateString) {
		const [datePart, timePart] = dateString.split(' ');
		const [day, month, year] = datePart.split('/');
		let hour = 0;
		let minutes = 0;
		if (timePart && !timePart.includes("(")) {
			[hour, minutes] = timePart.split(':');
		}
		const customDate = new Date(year, month - 1, day, hour, minutes);
		return customDate;
	}
	/**
	 * Initialise les titlepanes
	 */
	initTitlePanes() {
		$('.title-bar').click(function () {
			var titlePane = $(this).parent('.title-pane');
			if (titlePane.hasClass('open')) {
				titlePane.removeClass('open');
				titlePane.find('.content-pane').slideUp();
			} else {
				titlePane.addClass('open');
				titlePane.find('.content-pane').slideDown();
			}
		});
	}
	/**
	 * Ouvre la prochaine étape
	 * 
	 * @param {string} id_close -> id du pane à fermer
	 * @param {string} id_open -> id du pane à ouvrir
	 */
	titlePaneSlide(id_close, id_open) {

		//s'il faut fermer un pane
		if (id_close != null) {
			document.getElementById(id_close + "_form_errors").innerHTML = "";
			document.getElementById("valide_etape_" + id_close).style.visibility = "visible";
			$("#pane_" + id_close).removeClass('open');
			$("#pane_" + id_close).find('.content-pane').slideUp();
		}

		//affiche le formulaire complémentaire
		document.getElementById("pane_" + id_open).style.display = "block";
		document.getElementById("warning_etape_" + id_open).style.display = "none";
		document.getElementById(id_open + "_all").style.display = "block";
		if (!$("#pane_" + id_open).hasClass('open')) {
			$("#pane_" + id_open).addClass('open');
			$("#pane_" + id_open).find('.content-pane').slideDown();
		}
	}
	/**
	 * Récupère les communes
	 * 
	 * @param {bool} disabled -> true, false
	 * @param {int} valeur -> valeur
	 */
	getCommunes(disabled, valeur = null) {
		return new Promise(function (resolve, reject) {

			//récupère la liste des communes
			$.getJSON(javascript_common_vars.url + "presentation/get_store/communes", function (data) {
				const select_commune = $("#commune_etablissement").selectize({
					plugins: ["restore_on_backspace"],
					delimiter: " - ",
					persist: false,
					maxItems: 1,
					valueField: "id",
					labelField: "commune",
					searchField: ["commune", "code_postal"],
					options: data,
					render: {
						item: function (item, escape) {
							return "<div>" + escape(item.code_postal) + " - " + escape(item.commune) + "</div>";
						},
						option: function (item, escape) {
							return '<div>' + escape(item.code_postal) + ' - ' + escape(item.commune) + '</div>';
						}
					}
				});

				//valeur par défaut
				if (valeur != null) {
					select_commune[0].selectize.setValue(valeur);
				}

				//disable?
				if (disabled == true) {
					select_commune[0].selectize.disable();
				}

				//feedback
				resolve();
			}).fail(function (error) {
				reject(error);
			});
		});
	}
	/**
	 * Récupère les pays
	 * 
	 * @param {bool} tarif -> true, false
	 * @param {int} valeur -> valeur
	 */
	getPays(tarif, valeur = null) {
		return new Promise(function (resolve, reject) {

			//génère le select pays
			$.getJSON(javascript_common_vars.url + "presentation/get_store/pays", function (data) {
				const select_pays = $("#pays_etablissement").selectize({
					plugins: ["restore_on_backspace"],
					delimiter: " - ",
					persist: false,
					maxItems: 1,
					valueField: "id",
					labelField: "nom",
					searchField: ["nom"],
					options: data,
					onChange: function (value) {
						if (value != "") {
							const item = this.options[value];

							//met à jour la commune
							if (document.getElementById("etablissement_sans_uai")) {
								const select_commune = $("#commune_etablissement").selectize()[0].selectize;

								//si c'est un étab sans code uai
								if (document.getElementById("etablissement_sans_uai").checked == true) {
									if (item.has_code_postal == "true") {
										select_commune.enable();
										if (select_commune.getValue() == "99999") {
											select_commune.clear();
										}
									} else {
										select_commune.disable();
										select_commune.setValue("99999");
									}
								} else {
									//select_commune.clear();
									if (!document.getElementById("code_uai")) {
										select_commune.enable();
									}
								}
							}

							//update prix
							if (tarif == true) {
								javascript_page_vars.tva = parseFloat(item.tva);
								objCommons.updatePrix();
							}
						}
					}
				});

				//valeur par défaut
				select_pays[0].selectize.setValue(valeur);

				//feedback
				resolve();
			}).fail(function (error) {
				reject(error);
			});
		});
	}
	/**
	 * Récupère un établissement
	 */
	getEtablissement() {

		//désactive les champs
		document.getElementById("type_etablissement").disabled = true;
		document.getElementById("nom_etablissement").disabled = true;
		document.getElementById("adresse_etablissement").disabled = true;
		const select_commune = $("#commune_etablissement").selectize()[0].selectize;
		select_commune.disable();
		const select_pays = $("#pays_etablissement").selectize()[0].selectize;

		//s'il y a un code uai fourni
		if (document.getElementById("code_uai") && document.getElementById("code_uai").value != "" && document.getElementById("code_uai").checkValidity()) {

			//recupère les infos de l'établissement
			$.ajax({
				type: "POST",
				url: javascript_common_vars.url + "user/get_etablissement",
				data: {
					"code_uai": document.getElementById("code_uai").value
				},
				dataType: "json"
			}).done(function (data) {

				//s'il y a une réponse
				if (data.etablissement != "") {

					//page avec les champs cachés
					if (document.getElementById("infos_etablissement")) {
						document.getElementById("infos_etablissement").style.display = "table-row";
						document.getElementById("infos_etablissement_2").style.display = "table-row";
						document.getElementById("form_errors_etablissement").style.display = "none";
					}

					//valeur des champs
					document.getElementById("type_etablissement").value = data.etablissement.id_type;
					document.getElementById("nom_etablissement").value = data.etablissement.nom;
					document.getElementById("adresse_etablissement").value = data.etablissement.adresse;
					select_commune.setValue(data.etablissement.id_commune);
					select_pays.setValue(data.etablissement.id_pays);

					//désactive les champs
					document.getElementById("type_etablissement").disabled = true;
					document.getElementById("nom_etablissement").disabled = true;
					document.getElementById("adresse_etablissement").disabled = true;
					select_commune.disable();
				} else {

					//page avec les champs cachés
					if (document.getElementById("form_errors_etablissement")) {
						objCommons.showFormError("form_errors_etablissement", "Il n'y a aucun établissement avec ce code UAI.");
					} else {

						//valeur des champs
						document.getElementById("type_etablissement").value = "";
						document.getElementById("nom_etablissement").value = "";
						document.getElementById("adresse_etablissement").value = "";
						select_commune.clear();

						//réactive les champs
						document.getElementById("type_etablissement").disabled = false;
						document.getElementById("nom_etablissement").disabled = false;
						document.getElementById("adresse_etablissement").disabled = false;
						select_commune.enable();
					}
				}
			});
		} else {
			//page avec les champs cachés
			if (document.getElementById("form_errors_etablissement")) {
				document.getElementById("infos_etablissement").style.display = "none";
				document.getElementById("infos_etablissement_2").style.display = "none";
				document.getElementById("form_errors_etablissement").style.display = "none";
			}
		}
	}
	/**
	 * Verifie le justificatif
	 * 
	 * @param {string} form_errors -> id du form_errors
	 */
	checkJustificatif(form_errors) {

		//vérifie qu'il y a bien un fichier
		if (document.getElementById("fichier_justificatif").value == "") {
			objCommons.showFormError(form_errors, "Vous n'avez pas fourni de fichier.");
		} else {

			//envoie le fichier
			$.ajax({
				url: "justificatif",
				type: "POST",
				data: new FormData(document.getElementById("form_justificatif")),
				dataType: "json",
				processData: false,
				contentType: false
			}).done(function (data) {

				//en fonction de la réponse
				switch (data.message) {
					case "":
						objCommons.showFormError(form_errors, "Problème lors de l'importation du fichier. Veuillez recommencer.");
						document.getElementById("fichier_justificatif").value = "";
						break;
					case "filesize":
						objCommons.showFormError(form_errors, "Le fichier choisi est trop volumineux.");
						document.getElementById("fichier_justificatif").value = "";
						break;
					case "filetype":
						objCommons.showFormError(form_errors, "Ce type de fichier n'est pas pris en charge.");
						document.getElementById("fichier_justificatif").value = "";
						break;
					default:
						document.getElementById(form_errors).style.display = "none";
						document.getElementById("lien_justificatif").value = data.message;
						break;
				}
			}).fail(function () {

				//relance la fonction (bug incompréhensible)
				objCommons.checkJustificatif(form_errors);
			});
		}
	}
	/**
	 * Met à jour les prix ht et ttc en fonction du nombre de licences
	 */
	updatePrix() {

		//calcul du coefficient de conversion
		const coeff = (100 + javascript_page_vars.tva) / 100;

		//calcul du montant ht
		let montant_ht = javascript_page_vars.prix_licence * document.getElementById("nb_licences").value;

		//calcul du montant ttc
		let montant_ttc = montant_ht * coeff;

		//arrondi les montants
		montant_ht = montant_ht.toFixed(2);
		montant_ttc = montant_ttc.toFixed(2);

		//affiche le prix dans le formulaire
		document.getElementById("montant_ht").innerHTML = montant_ht.toString().replace(".", ",");
		document.getElementById("montant_ttc").innerHTML = montant_ttc.toString().replace(".", ",");
	}
	/**
	 * Change les parametres d'affichage d'un exercice
	 * 
	 * @param {string} champ -> type de changement (size, spacing...)
	 * @param {string} valeur -> valeur du changement
	 */
	parametresAffichage(champ, valeur) {

		//url en fonction du from
		if (javascript_common_vars.statut == "eleve" || javascript_common_vars.statut == "etudiant" || (javascript_common_vars.statut == "orthophoniste" && javascript_page_name != "patientsDomicile") || javascript_common_vars.statut == "patient") {

			//met à jour les vars
			javascript_page_vars.affichage.font_size = document.getElementById("font_size").value;
			javascript_page_vars.affichage.line_height = document.getElementById("line_height").value;
			javascript_page_vars.affichage.letter_spacing = document.getElementById("letter_spacing").value;
			javascript_page_vars.affichage.word_spacing = document.getElementById("word_spacing").value;
			javascript_page_vars.affichage.font_family = document.getElementById("font_family").value;
			javascript_page_vars.affichage.lire_couleur = document.getElementById("lire_couleur").value;

			//envoi de la requete au serveur
			let url = "../update_parametres_eleve";
			switch (javascript_common_vars.statut) {
				case "patient":
					url = "update_parametres_patient";
					break;
				case "orthophoniste":
					url = "../update_parametres_patient";
					break;
			}
			$.ajax({
				type: "POST",
				url: url,
				data: {
					"champ": champ,
					"valeur": valeur
				}
			});
		}

		//si c'est pas les phonèmes
		if (champ != "phonemes") {

			//si on est sur firefox
			if (navigator.userAgent.indexOf("Firefox") !== -1) {

				//transforme la chaine pour correspondre aux valeurs de ff
				var champ_temp = champ.split("_");
				champ_temp = champ_temp[0] + champ_temp[1].charAt(0).toUpperCase() + champ_temp[1].substring(1).toLowerCase();
			} else {
				var champ_temp = champ.replace("_", "-");
			}

			//modifie le contenu avec la nouvelle valeur
			if (champ == "font_family") {
				valeur = javascript_common_vars.polices[valeur];
				document.getElementById("div_contenu_item").style[champ_temp] = valeur;
			} else {
				document.getElementById("div_contenu_item").style[champ_temp] = valeur + "px";
			}
		}
	}
	/**
	 * Affiche le dialog des auteurs
	 * 
	 * @param {int} id_module -> id du module
	 */
	showAuteurs(id_module) {

		//recupere le contenu du dialog
		$.ajax({
			type: "POST",
			url: javascript_common_vars.url + "utilisateur/get_auteurs",
			data: {
				"id_module": id_module
			},
			dataType: "json"
		}).done(function (data) {

			//id du dialog
			const id_dialog = "dialog_auteurs";
			objDialog.setId(id_dialog);

			//si le dialog n'existe pas
			if (!document.getElementById(id_dialog)) {

				//création du dialog
				objDialog.create("Liste des auteurs", false);

				//message d'alerte
				const div = document.createElement("div");
				div.id = "dialog_auteurs_content";
				objDialog.updateContent(div);
			}

			//numéro du bordereau
			document.getElementById("dialog_auteurs_content").innerHTML = data.auteurs;

			//ouvre le dialog
			objDialog.show();
		});
	}
	/**
	 * Lit un texte
	 * 
	 * @param {string} id_element -> id de l'élément qui doit être lu
	 */
	speak(id_element) {
		window.utterances = [];
		javascript_page_vars.lecture_audio_element = id_element;
		javascript_page_vars.lecture_audio = true;

		const synth = window.speechSynthesis;
		const voices = synth.getVoices();
		const frVoice = voices.find(voice => voice.lang === 'fr-FR' || voice.lang === 'fr_FR');

		if (frVoice) {
			synth.cancel();
			clearInterval(javascript_page_vars.speechInterval);

			const text = document.getElementById(id_element).textContent.replace("œ", "oe").replace(/_/g, "");
			const msg = new SpeechSynthesisUtterance(text);

			msg.voice = frVoice;
			msg.pitch = 1;
			msg.rate = 1;
			msg.lang = "fr-FR";
			msg.onend = () => clearInterval(javascript_page_vars.speechInterval);

			utterances.push(msg);
			synth.speak(msg);

			if (navigator.userAgent.indexOf("Chrome") !== -1) {
				javascript_page_vars.speechInterval = setInterval(() => {
					synth.pause();
					synth.resume();
				}, 10000);
			}
		} else {
			setTimeout(function () {
				objCommons.speak(javascript_page_vars.lecture_audio_element);
			}, 10);
		}
	}
	/**
	 * Switch les onglets
	 * 
	 * @param {string} onglet -> onglet sélectionné
	 */
	switchOnglet(onglet) {

		//sélectionne l'onglet
		document.querySelectorAll(".onglet_selected").forEach(function (element) {
			element.className = "onglet";
		});
		document.getElementById(onglet).className = "onglet_selected";

		//affcihe le contenu
		document.querySelectorAll(".contenu_onglet").forEach(function (element) {
			element.style.display = "none";
		});
		document.getElementById("onglet_" + onglet).style.display = "";
	}
	/**
	 * Switch les onglets des parametres
	 * 
	 * @param {string} id_dialog -> id du dialog
	 * @param {string} selected_tab -> onglet sélectionné
	 */
	switchOngletParametres(id_dialog, selected_tab) {

		//sélectionne l'onglet
		document.querySelectorAll("#dialog_" + id_dialog + " .parametres_menu_selected").forEach(function (onglet) {
			onglet.classList.remove("parametres_menu_selected");
			onglet.classList.add("parametres_menu_normal");
		});
		document.getElementById(selected_tab).classList.remove("parametres_menu_normal");
		document.getElementById(selected_tab).classList.add("parametres_menu_selected");

		//affiche le contenu
		document.querySelectorAll("#dialog_" + id_dialog + " .parametres_contenus > div").forEach(function (contenu) {
			contenu.style.display = "none";
		});
		document.getElementById(selected_tab + "_contenu").style.display = "";
	}
}